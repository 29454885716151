import { Card, CardContent, Container, Paper, Step, StepLabel, Toolbar, Typography, Grid, TextField, Button, TableContainer, TableBody, TableRow, Table, TableHead, TableCell, CardMedia, CardActions, Select, MenuItem, InputLabel, Input, Slider, CardHeader, InputAdornment, Breadcrumbs, Accordion, AccordionSummary, AccordionDetails, styled, containerClasses, Modal, Dialog, DialogTitle, DialogContent, DialogContentText, IconButton, Alert } from '@mui/material';
import React, { useState } from 'react';
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'

import { Link, RouterProvider, useParams } from 'react-router-dom';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';

import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';

import InfoIcon from '@mui/icons-material/Info';

import MuiAccordionSummary, {
    AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import Info from '@mui/icons-material/Info';
import { calculateRepayments, finances, getAnnualIncome, getCredit, getExpense, getIncome, getIncomeObj, settings, updateCredit, updateExpense, updateIncome } from '../store/store';
import { MoneyFormat, PercentageFormat } from '../money';
import { useFinances } from '../store/FinancesContext';

function Tax() {

    var { year } = useParams()
    year = parseInt(year)

    var finances = useFinances()

    var [who, setWho] = React.useState('you')

    var calc = finances.annualIncome[parseInt(year)]

    calc = calc.incomes.find((f) => f.who === who)

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid item xs={12}>
                        <InputLabel htmlFor="standard-adornment-amount">Who</InputLabel>
                        <Select
                            id="who"
                            value={who}
                            onChange={(e) => {
                                setWho(e.target.value)
                            }}
                            fullWidth
                        >
                            <MenuItem value='you'>You</MenuItem>
                            <MenuItem value='partner'>Partner</MenuItem>
                        </Select>
                    </Grid>
                </Grid>

                <Grid container item spacing={2} xs={12}>
                    <Grid item xs={12}>
                        <Accordion defaultExpanded={false}>
                            <AccordionSummary><Typography variant='h6'>Tax Values ({year})</Typography></AccordionSummary>
                            <AccordionDetails>
                                <Grid container item spacing={2}>
                                    <Grid item xs={12}>
                                        <Alert severity='info'>These values are predicted based historical trends</Alert>
                                    </Grid>
                                    <Grid container item spacing={2} xs={12}>
                                        <Grid item xs={6}>
                                            <InputLabel htmlFor="standard-adornment-amount">Personal Allowance</InputLabel>
                                            <TextField InputProps={{
                                                inputComponent: MoneyFormat,
                                                startAdornment: <InputAdornment position="start">£</InputAdornment>,

                                            }} fullWidth id="outlined-basic" disabled defaultValue={Math.round(calc?.personalAllowance)} variant="standard" />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <InputLabel htmlFor="standard-adornment-amount">Upper Threshold</InputLabel>
                                            <TextField InputProps={{
                                                inputComponent: MoneyFormat,
                                                startAdornment: <InputAdornment position="start">£</InputAdornment>,

                                            }} fullWidth id="outlined-basic" disabled defaultValue={Math.round(calc?.upperThreshold)} variant="standard" />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} item xs={12}>
                                        <Grid item xs={6}>
                                            <InputLabel htmlFor="standard-adornment-amount">Basic Tax Rate</InputLabel>
                                            <TextField InputProps={{
                                                inputComponent: PercentageFormat,
                                            }} fullWidth id="outlined-basic" disabled defaultValue={settings.tax.basicTaxRate.find((f) => year >= f.start && (year <= f.end || f.end == null)).percentage * 100} variant="standard" />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <InputLabel htmlFor="standard-adornment-amount">Upper Tax Rate</InputLabel>
                                            <TextField InputProps={{
                                                inputComponent: PercentageFormat,
                                            }} fullWidth id="outlined-basic" disabled defaultValue={settings.tax.upperTaxRate.find((f) => year >= f.start && (year <= f.end || f.end == null)).percentage * 100} variant="standard" />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} item xs={12}>
                                        <Grid item xs={6}>
                                            <InputLabel htmlFor="standard-adornment-amount">Basic National Insurance Rate</InputLabel>
                                            <TextField InputProps={{
                                                inputComponent: PercentageFormat,
                                            }} fullWidth id="outlined-basic" disabled defaultValue={settings.tax.basicNIRate.find((f) => year >= f.start && (year <= f.end || f.end == null)).percentage * 100} variant="standard" />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <InputLabel htmlFor="standard-adornment-amount">Upper National Insurance Rate</InputLabel>
                                            <TextField InputProps={{
                                                inputComponent: PercentageFormat,
                                            }} fullWidth id="outlined-basic" disabled defaultValue={settings.tax.upperNIRate.find((f) => year >= f.start && (year <= f.end || f.end == null)).percentage * 100} variant="standard" />
                                        </Grid>
                                    </Grid>
                                </Grid>


                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Paper style={{ padding: '20px' }}>

                        <Grid container spacing={2} item xs={12}>
                            <Grid item xs={12}>
                                <Typography variant='h6'>Tax Calculation ({year})</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <InputLabel htmlFor="standard-adornment-amount">Total Income</InputLabel>
                                <TextField InputProps={{
                                    inputComponent: MoneyFormat,
                                    startAdornment: <InputAdornment position="start">£</InputAdornment>,

                                }} fullWidth id="outlined-basic" disabled value={Math.round(calc?.totalGrossIncome)} variant="standard" />

                            </Grid>
                            <Grid container item spacing={2} xs={12}>
                                <Grid item xs={12} md={6}>
                                    <InputLabel htmlFor="standard-adornment-amount">Gross Taxable Income</InputLabel>
                                    <TextField InputProps={{
                                        inputComponent: MoneyFormat,
                                        startAdornment: <InputAdornment position="start">£</InputAdornment>,

                                    }} fullWidth id="outlined-basic" disabled value={Math.round(calc?.totalGrossTaxableIncomeBeforeDeductions)} variant="standard" />

                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <InputLabel htmlFor="standard-adornment-amount">Pension Contribution</InputLabel>
                                    <TextField InputProps={{
                                        inputComponent: MoneyFormat,
                                        startAdornment: <InputAdornment position="start">£</InputAdornment>,

                                    }} fullWidth id="outlined-basic" disabled value={Math.round(calc?.pensionContribution)} variant="standard" />
                                </Grid>
                            </Grid>

                            <Grid container item spacing={2} xs={12}>
                                <Grid item xs={12}>
                                    <InputLabel htmlFor="standard-adornment-amount">Net Taxable Income</InputLabel>
                                    <TextField InputProps={{
                                        inputComponent: MoneyFormat,
                                        startAdornment: <InputAdornment position="start">£</InputAdornment>,

                                    }} fullWidth id="outlined-basic" disabled value={Math.round(calc?.totalGrossTaxableIncomeAfterDeductions)} variant="standard" />
                                </Grid>

                            </Grid>
                            <Grid container item spacing={2} xs={12}>

                                <Grid container item spacing={2} xs={12}>

                                    <Grid item xs={12} md={6}>
                                        <InputLabel htmlFor="standard-adornment-amount">Basic Rate Tax</InputLabel>
                                        <TextField InputProps={{
                                            inputComponent: MoneyFormat,
                                            startAdornment: <InputAdornment position="start">£</InputAdornment>,

                                        }} fullWidth id="outlined-basic" disabled value={Math.round(calc?.basicTax)} variant="standard" />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <InputLabel htmlFor="standard-adornment-amount">Upper Rate Tax</InputLabel>
                                        <TextField InputProps={{
                                            inputComponent: MoneyFormat,
                                            startAdornment: <InputAdornment position="start">£</InputAdornment>,

                                        }} fullWidth id="outlined-basic" disabled value={Math.round(calc?.higherTax)} variant="standard" />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container item spacing={2} xs={12}>
                                <Grid item xs={12} md={6}>
                                    <InputLabel htmlFor="standard-adornment-amount">Basic National Insurance</InputLabel>
                                    <TextField InputProps={{
                                        inputComponent: MoneyFormat,
                                        startAdornment: <InputAdornment position="start">£</InputAdornment>,

                                    }} fullWidth id="outlined-basic" disabled value={Math.round(calc?.basicNationalInsurance)} variant="standard" />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <InputLabel htmlFor="standard-adornment-amount">Higher National Insurance</InputLabel>
                                    <TextField InputProps={{
                                        inputComponent: MoneyFormat,
                                        startAdornment: <InputAdornment position="start">£</InputAdornment>,

                                    }} fullWidth id="outlined-basic" disabled value={Math.round(calc?.higherNationalInsurance)} variant="standard" />
                                </Grid>

                            </Grid>
                            <Grid container item spacing={2} xs={12}>
                                <Grid item xs={12}>
                                    <InputLabel htmlFor="standard-adornment-amount">Take Home</InputLabel>
                                    <TextField InputProps={{
                                        inputComponent: MoneyFormat,
                                        startAdornment: <InputAdornment position="start">£</InputAdornment>,

                                    }} fullWidth id="outlined-basic" disabled value={Math.round(calc?.totalTaxableTakeHome)} variant="standard" />
                                </Grid>
                            </Grid>
                        </Grid>

                    </Paper>
                </Grid>
            </Grid >

        </>


    );
}

export default Tax;
