import { Container, Grid, Typography } from '@mui/material';
import React from 'react';

function Privacy() {

    return <>
        <Container className='app-container'>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant='h4'>Privacy Notice</Typography>

                    <Typography paragraph>
                        This privacy notice tells you what to expect us to do with your personal information.
                    </Typography>

                    <Typography variant='h5'>Contact Details</Typography>
                    <Typography paragraph>simon@limonsolutions.net</Typography>

                    <Typography variant='h5'>What information we collect, use, and why</Typography>
                    <Typography paragraph>
                        We collect or use the following information to provide services and goods, including delivery:

                        <ul>
                            <li>Date of birth</li>
                            <li>Personal financial data (i.e. income, loans, expenses and savings), excluding account information</li>
                        </ul>
                    </Typography>

                    <Typography variant='h5'>Lawful basis</Typography>
                    <Typography paragraph>
                        Our lawful bases for collecting or using personal information to provide services and goods are:
                        <ul>
                            <li>Legitimate interest:</li>
                            <ul>
                                <li>By collecting personal information about you relating to your finances, we can provide you with information that assists you when making financial decisions. The information provided by the application should not however be your sole decision making instrument. Ensure you speak to qualified financial professionals if you are unsure on the decisions you are making.</li>
                            </ul>
                        </ul>
                    </Typography>

                    <Typography variant='h5'>Where we get personal information from</Typography>
                    <Typography paragraph>
                        People directly
                    </Typography>

                    <Typography variant='h5'>How long we keep information</Typography>
                    <Typography paragraph>
                        Information collected is stored on your own personal Google Drive (application-specific data) and can be removed by yourself following Google's guidance at any time.
                    </Typography>

                    <Typography variant='h5'>We share your information with</Typography>
                    <Typography paragraph>
                        <ul>
                            <li>Data processors</li>
                            <ul>
                                <li>Google</li>
                                <ul>
                                    <li>This data processor does the following activities for us: Your own Google Drive account (application-specific data) is used to store all data collected by the application.</li>
                                </ul>
                            </ul>
                        </ul>
                    </Typography>

                    <Typography variant='h5'>Sharing information outside the UK</Typography>
                    <Typography paragraph>
                        Where necessary, our data processors may share personal information outside of the UK. When doing so, they comply with the UK GDPR, making sure appropriate safeguards are in place. Please contact us for more information.
                    </Typography>

                    <Typography variant='h5'>Your data protection rights</Typography>
                    <Typography paragraph>
                        Under data protection law, you have rights including:

                        <ul>
                            <li>Your right of access - You have the right to ask us for copies of your personal data.</li>
                            <li>Your right to rectification - You have the right to ask us to rectify personal data you think is inaccurate. You also have the right to ask us to complete information you think is incomplete.</li>
                            <li>Your right to erasure - You have the right to ask us to erase your personal data in certain circumstances.</li>
                            <li>Your right to restriction of processing - You have the right to ask us to restrict the processing of your personal data in certain circumstances.</li>
                            <li>Your right to object to processing - You have the right to object to the processing of your personal data in certain circumstances.</li>
                            <li>Your right to data portability - You have the right to ask that we transfer the personal data you gave us to another organisation, or to you, in certain circumstances.</li>
                            <li>Your right to withdraw consent – When we use consent as our lawful basis you have the right to withdraw your consent.</li>
                        </ul>

                        You don’t usually need to pay a fee to exercise your rights. If you make a request, we have one calendar month to respond to you.
                        To make a data protection rights request, please contact us using the contact details at the top of this privacy notice.
                    </Typography>


                    <Typography variant='h5'>How to complain</Typography>
                    <Typography paragraph>
                        If you have any concerns about our use of your personal data, you can make a complaint to us using the contact details at the top of this privacy notice.
                        If you remain unhappy with how we’ve used your data after raising a complaint with us, you can also complain to the ICO.
                        The ICO’s address:
                        Information Commissioner’s Office
                        Wycliffe House
                        Water Lane
                        Wilmslow
                        Cheshire
                        SK9 5AF
                        Helpline number: 0303 123 1113
                        Website: https://www.ico.org.uk/make-a-complaint
                        Last updated
                        14 July 2024
                    </Typography>













                </Grid>
            </Grid>
        </Container>

    </>
}

export default Privacy