import { Card, CardContent, Container, Paper, Step, StepLabel, Toolbar, Typography, Grid, TextField, Button, TableContainer, TableBody, TableRow, Table, TableHead, TableCell, CardMedia, CardActions, Select, MenuItem, InputLabel, Input, Slider, CardHeader, InputAdornment, Breadcrumbs, Accordion, AccordionSummary, AccordionDetails, styled, containerClasses, Modal, Dialog, DialogTitle, DialogContent, DialogContentText, IconButton, Alert, FormControlLabel, Checkbox } from '@mui/material';
import React, { useState } from 'react';
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'

import { Link, RouterProvider, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';

import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';

import InfoIcon from '@mui/icons-material/Info';

import MuiAccordionSummary, {
    AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import Info from '@mui/icons-material/Info';
import { calculateRepayments, createIncome, finances, getCredit, getExpense, getIncome, getIncomeObj, saveJson, updateCredit, updateExpense, updateIncome } from '../store/store';

import { MoneyFormat, MoneyTextFormat, PercentageFormat } from '../money'
import MonthlyAnnualAmmount from '../MonthlyAnnualAmount';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { useFinances, useFinancesDispatch } from '../store/FinancesContext';
import { DataGrid } from '@mui/x-data-grid';
import DeleteDialog from '../deletedialog';

import ListSubheader from '@mui/material/ListSubheader';
import Period from '../periods/Period';


function Income() {
    var { incomeId } = useParams()
    var year = useSearchParams()[0].get('year')

    var navigate = useNavigate()

    const [showDelete, setShowDelete] = React.useState()


    var finances = useFinances()
    var financesDispatch = useFinancesDispatch()

    var [selectedPeriod, setSelectedPeriod] = useState(null)

    var income = finances.income.find((f) => f.id == incomeId)

    var [income, setIncome] = useState(income ? income : {
        name: "",
        taxable: false,
        periods: [
            {
                start: null,
                end: null,
                gross: null,
            }
        ],
        pension: {
            takePensionAge: 65,
            lumpSum: 0.2
        }
    })

    income.periods.sort((a, b) => {
        if (a.start == null || b.start == null) {
            return 1
        }

        return a.start - b.start
    })

    if (!selectedPeriod) {
        if (year) {
            setSelectedPeriod(income.periods[income.periods.findIndex((f) => year == f.start)])
        } else {
            setSelectedPeriod(income.periods[income.periods.length - 1])
        }

    }

    return (
        <>
            {showDelete &&
                <DeleteDialog delete={showDelete.delete} name={showDelete.name} handleClose={() => setShowDelete(null)} />
            }
            <Grid container spacing={2}>
                <Grid container item xs={12}>
                    <Paper style={{ width: '100%', padding: '20px' }}>
                        <Grid item xs={12} container spacing={2}>
                            <Grid item xs={12} md={4}>
                                <InputLabel htmlFor="standard-adornment-amount">Who</InputLabel>
                                <Select
                                    id="who"
                                    value={income.who}
                                    onChange={(e) => {
                                        setIncome({
                                            ...income,
                                            who: e.target.value
                                        })
                                    }}
                                    fullWidth
                                >
                                    <MenuItem value='you'>You</MenuItem>
                                    <MenuItem value='partner'>Partner</MenuItem>
                                </Select>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <InputLabel htmlFor="standard-adornment-amount">From</InputLabel>
                                <Select
                                    id="type"
                                    value={income.from}
                                    onChange={(e) => {
                                        setIncome({
                                            ...income,
                                            from: e.target.value
                                        })
                                    }}
                                    fullWidth
                                >
                                    <MenuItem value='employer'>Employer</MenuItem>
                                    <MenuItem value='other'>Other (i.e. benefits)</MenuItem>
                                    <ListSubheader>Savings & Investments</ListSubheader>
                                    {
                                        finances.savings.map((m) => {
                                            return <MenuItem value={m.id}>{m.name}</MenuItem>
                                        })
                                    }
                                </Select>
                            </Grid>
                            {income.from == 'employer' && <Grid item xs={12} md={4}>
                                <InputLabel htmlFor="standard-adornment-amount">Workplace Pension</InputLabel>
                                <Select
                                    id="type"
                                    value={income.pension?.type}
                                    onChange={(e) => {
                                        if (!income.pension) {
                                            income.pension = {}
                                        }
                                        income.pension.type = e.target.value
                                        setIncome({
                                            ...income
                                        })
                                    }}
                                    fullWidth
                                >
                                    <MenuItem value='none'>None</MenuItem>
                                    <MenuItem value='definedcontribution'>Defined Contribution</MenuItem>
                                    <MenuItem value='definedbenefit'>Defined Benefit</MenuItem>
                                </Select>
                            </Grid>}
                            <Grid item xs={12}>
                                <InputLabel htmlFor="standard-adornment-amount">Name</InputLabel>
                                <TextField variant='standard' fullWidth id="outlined-basic" defaultValue={income.name} onChange={(e) => { setIncome({ ...income, name: e.target.value }) }} />
                            </Grid>
                            <Grid container spacing={2} item xs={12}>
                                <Grid item xs={4}>
                                    <FormControlLabel control={<Checkbox checked={income.taxable} onChange={(e) => {
                                        setIncome({
                                            ...income,
                                            taxable: !income.taxable
                                        })
                                    }} />} label="Taxable" />
                                </Grid>
                                <Grid item xs={8}>
                                    <FormControlLabel control={<Checkbox checked={income.inflate} onChange={(e) => {
                                        setIncome({
                                            ...income,
                                            inflate: !income.inflate
                                        })
                                    }} />} label="Rise with Inflation" />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                {income.pension?.type != 'none' && income.pension?.type &&
                    <>
                        <Grid item xs={12}>
                            <Typography variant='h5'>Pension Drawdown</Typography>
                        </Grid>
                        <Grid container spacing={2} item xs={12}>
                            <Grid item xs={12}>
                                <Paper style={{ padding: '20px' }}>
                                    <Grid container spacing={2} item xs={12}>

                                        <Grid item xs={12} md={6}>
                                            <InputLabel htmlFor="standard-adornment-amount">Planned Retirement Age</InputLabel>
                                            <TextField fullWidth id="outlined-basic" variant="outlined" value={income.pension?.takePensionAge} onChange={(e) => {
                                                income.pension.takePensionAge = parseInt(e.target.value)
                                                setIncome({
                                                    ...income
                                                })
                                            }} />
                                        </Grid>

                                        <Grid item xs={12} md={6}>
                                            <InputLabel htmlFor="standard-adornment-amount">Lump Sum</InputLabel>
                                            <TextField InputProps={{
                                                inputComponent: PercentageFormat,
                                            }} fullWidth id="outlined-basic" variant="outlined" defaultValue={income.pension?.lumpSum * 100} endAdornment={<InputAdornment>%</InputAdornment>} onChange={(e) => {
                                                income.pension.lumpSum = parseFloat(e.target.value) / 100
                                                setIncome({
                                                    ...income
                                                })
                                            }} />
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        </Grid>
                    </>
                }
                <Period monthly={true} periods={income.periods} selectedPeriod={selectedPeriod} update={() => {
                    setIncome({
                        ...income
                    })
                }} editLink={(selYear) => {
                    var p = selYear && income.periods.find((f) => selYear.format('YYYY-MM-DD') == f.start)
                    setSelectedPeriod(p ? p : income.periods[income.periods.length - 1])
                }} getAmount={(period) => {
                    return period.gross
                }} addPeriod={(periods) => {
                    periods.push({
                        start: null,
                        end: null,
                        gross: null,
                    })
                }}>
                    <Grid item xs={12}>
                        <MonthlyAnnualAmmount key={`gross-${selectedPeriod?.end}`} label='Earnings (Gross)' value={selectedPeriod?.gross} update={(e) => {
                            setIncome({
                                ...income,
                                periods: income.periods.map((m) => {
                                    if (m.start == selectedPeriod?.start) {
                                        m.gross = parseFloat(e)
                                    }

                                    return m
                                })
                            })
                        }} />
                    </Grid>
                    {income.from == 'employer' &&
                        <>
                            <Grid item xs={12}>
                                <Typography variant='h6'>Period Pension Contributions</Typography>
                            </Grid>

                            <Grid container spacing={2} item xs={12}>

                                {income.pension?.type == 'definedcontribution' && <Grid item xs={12}>
                                    <InputLabel htmlFor="standard-adornment-amount">Starting Pension Size</InputLabel>
                                    <TextField InputProps={{
                                        inputComponent: MoneyFormat,
                                        startAdornment: <InputAdornment position="start">£</InputAdornment>,
                                    }} fullWidth id="outlined-basic" value={selectedPeriod?.startingPensionSize} key={`size-${selectedPeriod?.start}`} onChange={(e) => {
                                        setIncome({
                                            ...income,
                                            periods: income.periods.map((m) => {
                                                if (m.start == selectedPeriod?.start) {
                                                    m.startingPensionSize = parseFloat(e.target.value)
                                                }

                                                return m
                                            })
                                        })
                                    }} variant="outlined" />
                                </Grid>}
                                <Grid item xs={12} md={6}>
                                    <InputLabel htmlFor="standard-adornment-amount">Your Contribution</InputLabel>
                                    <TextField InputProps={{
                                        inputComponent: PercentageFormat,
                                    }} fullWidth id="outlined-basic" key={`employee-${selectedPeriod?.start}`} defaultValue={selectedPeriod?.employeeContribution * 100} onChange={(e) => {
                                        setIncome({
                                            ...income,
                                            periods: income.periods.map((m) => {
                                                if (m.start == selectedPeriod?.start) {
                                                    m.employeeContribution = parseFloat(e.target.value) / 100
                                                }

                                                return m
                                            })
                                        })
                                    }} variant="outlined" />
                                </Grid>
                                {income.pension?.type == 'definedcontribution' && <Grid item xs={12} md={6}>
                                    <InputLabel htmlFor="standard-adornment-amount">Employers Contribution</InputLabel>
                                    <TextField InputProps={{
                                        inputComponent: PercentageFormat,
                                    }} fullWidth id="outlined-basic" key={`employer-${selectedPeriod?.start}`} defaultValue={selectedPeriod?.employerContribution * 100} onChange={(e) => {
                                        setIncome({
                                            ...income,
                                            periods: income.periods.map((m) => {
                                                if (m.start == selectedPeriod?.start) {
                                                    m.employerContribution = parseFloat(e.target.value) / 100
                                                }

                                                return m
                                            })
                                        })
                                    }} variant="outlined" />
                                </Grid>}
                            </Grid>
                        </>
                    }


                </Period>
                <Grid container item spacing={2} justifyContent='flex-end'>
                    <Grid item>
                        <Button variant='outlined' onClick={(a) => {
                            setShowDelete({
                                delete: () => {
                                    financesDispatch({
                                        type: 'delete',
                                        financeType: 'income',
                                        id: income.id
                                    })
                                    setShowDelete(null)
                                    navigate(-1)
                                },
                                name: income.name
                            })
                        }}>Delete</Button>
                    </Grid>
                    <Grid item>
                        {income.id ? <Button onClick={() => { financesDispatch({ type: 'update', financeType: 'income', item: income }); navigate(-1) }} variant='contained'>Update Income</Button> : <Button onClick={() => {
                            financesDispatch({
                                type: 'add',
                                financeType: 'income',
                                item: { ...income, id: income.name }
                            })
                            navigate(-1)
                        }
                        } variant='contained'>Add Income</Button>}
                    </Grid>

                </Grid>

            </Grid >
        </>


    );
}

export default Income;
