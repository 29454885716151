import logo from './logo.svg';
import './App.css';
import React, { useEffect, useReducer, useRef, useState } from 'react';
import { AppBar, Container, Paper, Step, StepLabel, Toolbar, Typography, Grid, TextField, Button, IconButton, Drawer, Divider, List, ListItem, Avatar, Menu, MenuItem, Chip, Card, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText, Select, InputLabel, Alert } from '@mui/material';
import SailingIcon from '@mui/icons-material/Sailing';
import MenuIcon from '@mui/icons-material/Menu'

import { Link, Navigate, Outlet, RouterProvider, createBrowserRouter, useLocation, useNavigate, useParams } from "react-router-dom"
import BookmarkIcon from '@mui/icons-material/Bookmark';
import { createScenario, deleteJson, demoFinance, finances, getAnnualIncome, loadDemo, loadJson, loadScenario, loadScenarios, loadSubscriptionId, saveJson, scenarios, setFinances, storeSubscriptionId } from './store/store';
import ScrollToTop from './ScrollToTop';
import Collect from './welcome/Collect';

import { Chart, LogarithmicScale } from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';

import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { useFinances, useFinancesDispatch } from './store/FinancesContext';
import DeleteDialog from './deletedialog';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'

import SavingsIcon from '@mui/icons-material/Savings';
import { ErrorBoundary } from 'react-error-boundary';

dayjs.extend(utc)

Chart.register(zoomPlugin);

function App() {

  var { year } = useParams()

  var finances = useFinances()
  var financesDispatch = useFinancesDispatch()

  var navigate = useNavigate()

  var [scenarios, setScenarios] = React.useState([])

  const [showDelete, setShowDelete] = React.useState()


  var [scenario, setScenario] = React.useState()
  var [tmpScenario, setTmpScenario] = React.useState()
  var [changeScenario, setChangeScenario] = React.useState()


  var [changeScenarioOpen, setChangeScenarioOpen] = React.useState(false)
  var [createScenarioOpen, setCreateScenarioOpen] = React.useState(false)


  const initialOptions = {
    "client-id": "ARFIec8aC988P6Aii1m5O5ahwyALrlXQV73usuu7N4tOxXf0HcUaq6K2uT3728IQR4HJPBLUrElXtc7c",
    "enable-funding": "",
    "disable-funding": "paylater,card",
    "data-sdk-integration-source": "integrationbuilder_sc",
    vault: "true",
    intent: "subscription",
  };



  if (!parseInt(year)) {
    year = new Date().getFullYear()
  }

  var [userDetails, setUserDetails] = useState(null)
  var [accessToken, setAccessToken] = useState(window.sessionStorage.getItem('access-token'))

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [userAnchor, setUserAnchor] = React.useState(null);


  const mainMenuOpen = Boolean(anchorEl);
  const userMenuOpen = Boolean(userAnchor);

  var [collect, setCollect] = React.useState(false)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleUserClick = (event) => {
    setUserAnchor(event.currentTarget);
  };

  const handleUserDelete = () => {
    deleteJson(scenario).then(() => {
      window.location.assign("/")
    })
  };

  const handleUserClose = () => {
    setUserAnchor(null);
  };


  var [client, setClient] = React.useState(null)

  useEffect(() => {
    if (tmpScenario) {
      loadScenario(tmpScenario).then((f) => {
        setFinances(f, tmpScenario)
        financesDispatch({
          type: 'reload',
          item: f
        })
        setScenario(tmpScenario)
        navigate(`/annual/${dayjs().year()}`)

        handleUserClose()
      })
    }
  }, [tmpScenario])

  var location = useLocation()
  var [showPaypal, setShowPayPal] = React.useState(false)

  /*useEffect(() => {
    setShowPayPal(Math.random() > 0.5)
  }, [location.pathname])*/

  var [subscribed, setSubscribed] = React.useState(true)

  /*if (window.gapi && window.gapi.client && window.gapi.client.drive && !subscribed) {
    loadSubscriptionId().then((f) => {
      console.log("subscription id ", f)
      fetch("https://uyl2tiz4il5dq62w5gp3bweanm0ergzw.lambda-url.eu-north-1.on.aws/?id=" + f).then((res) => {
        const resp = res.json().then((status) => {
          setSubscribed(status.status == "ACTIVE")
        })
      })
    })
  }*/



  useEffect(() => {
    if (accessToken) {
      window.gapi.load('client', (a) => {
        window.gapi.client.load("https://people.googleapis.com/$discovery/rest?version=v1").then((f) => {

          window.gapi.auth.setToken({
            access_token: accessToken
          })

          window.gapi.client.people.people.get({
            'resourceName': 'people/me',
            'personFields': 'names,photos'
          }).then((res) => {
            console.log(res)
            window.gapi.client.load("https://www.googleapis.com/discovery/v1/apis/drive/v3/rest").then((d) => {
              loadScenarios().then((dres) => {
                if (dres.length > 0) {
                  scenarios.splice(0, scenarios.length)
                  dres.forEach((f) => {
                    f.name = f.name.substring(0, f.name.length - 5)
                    scenarios.push(f)
                  })
                  setScenarios([...scenarios])

                  setTmpScenario(dres[dres.length - 1].id)
                } else {
                  saveJson(finances).then(() => {
                    window.location.reload()
                  })
                }

                setUserDetails(res.result)
              })
            })
          }).catch((e) => {
            window.sessionStorage.clear()
          })



          console.log("end")
        })


      })

    }
  }, [accessToken])


  useEffect(() => {
    if (window.google) {
      setClient(window.google.accounts.oauth2.initTokenClient({
        client_id: "877680423928-mca1tk009r9t7edtfaco2nsq5b5bpfq6.apps.googleusercontent.com",
        scope: 'https://www.googleapis.com/auth/drive.appdata \ https://www.googleapis.com/auth/userinfo.profile',
        callback: (res) => {
          console.log(res)
          //window.sessionStorage.setItem("auth", JSON.stringify(atob(res.credential.split('.')[1])))

          window.sessionStorage.setItem("access-token", res.access_token)
          setAccessToken(res.access_token)
          //setUserDetails(JSON.parse(atob(res.credential.split('.')[1])))


        },
      }))
    }

  }, [])

  var scenarioName = null

  return (
    <>
      {showDelete &&
        <DeleteDialog delete={showDelete.delete} name={showDelete.name} handleClose={() => setShowDelete(null)} />
      }
      <Dialog
        open={changeScenarioOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Change Scenario
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography paragraph>Select the scenario you wish to view</Typography>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              defaultValue={scenario}
              value={changeScenario}
              onChange={(e) => {
                setChangeScenario(e.target.value)
              }}
              fullWidth
            >
              {
                [scenarios.map((m) => {
                  return <MenuItem value={m.id}>{m.name}</MenuItem>
                })]
              }

            </Select>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setChangeScenarioOpen(false)}>Cancel</Button>
          <Button onClick={() => {
            setTmpScenario(changeScenario)
            setChangeScenarioOpen(false)
          }} autoFocus>
            Change
          </Button>
        </DialogActions>
      </Dialog>


      <Dialog
        open={createScenarioOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Create Scenario
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography paragraph>Choose a scenario from which you wish to base a new scenario on.</Typography>
            <Grid container spacing={2} item xs={12}>
              <Grid item xs={12}>
                <InputLabel htmlFor="standard-adornment-amount">From</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={changeScenario}
                  onChange={(e) => {
                    setChangeScenario(e.target.value)
                  }}
                  fullWidth
                >
                  {
                    [scenarios.map((m) => {
                      return <MenuItem value={m.id}>{m.name}</MenuItem>
                    })]
                  }

                </Select>
              </Grid>
              <Grid item xs={12}>
                <InputLabel htmlFor="standard-adornment-amount">New Scenario Name</InputLabel>
                <TextField variant='standard' fullWidth id="outlined-basic" onChange={(e) => {
                  scenarioName = e.target.value
                }} />
              </Grid>
            </Grid>

          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCreateScenarioOpen(false)}>Cancel</Button>
          <Button onClick={() => {
            createScenario(scenarioName, changeScenario ? changeScenario : scenario).then(() => {
              loadScenarios().then((dres) => {
                console.log("drive list")
                console.log(dres)
                scenarios.splice(0, scenarios.length)
                dres.forEach((f) => {
                  f.name = f.name.substring(0, f.name.length - 5)
                  scenarios.push(f)
                })
                console.log(scenarios)
                setTmpScenario(dres.find((f) => f.name === `${scenarioName}`).id)
                handleUserClose()
              })
            })

            //setScenario(tmpScenario)
            setCreateScenarioOpen(false)
          }} autoFocus>
            Create
          </Button>
        </DialogActions>
      </Dialog>
      <ScrollToTop />
      <AppBar position="static">
        <Container>
          <Toolbar disableGutters>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleClick}
              edge="start"
            >
              <MenuIcon />
            </IconButton>
            <SavingsIcon sx={{ mr: 1 }}></SavingsIcon>
            <Typography
              variant="h6"
              noWrap
              component="a"
              href="/"
              sx={{
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',

              }}
            >
              MYFINANCE
            </Typography>
            <div style={{
              flexGrow: 1
            }}></div>
            {
              client && userDetails && userDetails != 'login' ? <IconButton>
                <Avatar alt="Remy Sharp" onClick={handleUserClick} src={userDetails.photos[0].url} />
              </IconButton> : <button onClick={() => {
                client.requestAccessToken()
              }} class="gsi-material-button">
                <div class="gsi-material-button-state"></div>
                <div class="gsi-material-button-content-wrapper">
                  <div class="gsi-material-button-icon">
                    <svg version="1.1" viewBox="0 0 48 48" style={{ display: 'block' }}>
                      <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
                      <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
                      <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
                      <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path>
                      <path fill="none" d="M0 0h48v48H0z"></path>
                    </svg>
                  </div>
                  <span class="gsi-material-button-contents">Sign in</span>
                  <span style={{ display: 'none' }}>Sign in with Google</span>
                </div>
              </button>
            }


            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={mainMenuOpen}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              {
                (userDetails || finances.expenses?.length > 0) &&
                <div>
                  <MenuItem onClick={handleClose}><Link to={`/annual/${year}`}>Budget</Link></MenuItem>
                  <MenuItem onClick={handleClose}><Link to="/outlook">Planner</Link></MenuItem>
                  <MenuItem onClick={handleClose}><Link to="/pension">Pension</Link></MenuItem>

                </div>
              }
              {(!userDetails && finances.expenses?.length == 0) &&
                <MenuItem onClick={() => {
                  handleClose()
                  financesDispatch({
                    type: 'reload',
                    item: demoFinance
                  })
                  setCollect(true)
                  navigate(`/annual/${year}`)
                }}>Demo</MenuItem>

              }
              <MenuItem onClick={handleClose}><Link to="/privacy">Privacy</Link></MenuItem>
              <MenuItem><Chip color='success' label={process.env.REACT_APP_VERSION} sx={{
                maxWidth: '100px',
              }} /></MenuItem>
            </Menu>


            <Menu
              id="user-menu"
              anchorEl={userAnchor}
              open={userMenuOpen}
              onClose={handleUserClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              {scenario && <MenuItem>{scenarios.find((f) => scenario == f.id).name}</MenuItem>}
              <Divider component="li" variant='middle' />
              <MenuItem disabled={!subscribed} onClick={() => setChangeScenarioOpen(true)}>Change Scenario</MenuItem>
              <MenuItem disabled={!subscribed} onClick={() => setCreateScenarioOpen(true)}>Add Scenario</MenuItem>
              <MenuItem disabled={!subscribed} onClick={() => {
                setShowDelete({
                  delete: () => {
                    handleUserDelete()
                    setShowDelete(null)
                    //navigate(`/annual/${year}`)
                  },
                  name: `the scenario ${scenarios.find((f) => scenario == f.id).name}`
                })
              }}>
                Delete Scenario
              </MenuItem>
              <Divider component="li" variant='middle' />
              <MenuItem onClick={() => {
                window.sessionStorage.clear()
                navigate("/")
                window.location.reload()
              }}>Log Out</MenuItem>
            </Menu>
          </Toolbar>

        </Container>
      </AppBar >

      <Container className='app-container'>


        <>
          {userDetails && showPaypal && (<Paper style={{ padding: '20px', marginTop: '20px' }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography>For £3 a month, subscribe and create as many financial scenarios as you need. Can you afford a new house? What if you overpaid your mortgage vs investing in an ISA? What if you added a bit more to your pension? </Typography>
              </Grid>
              <Grid item xs={12}>
                <PayPalScriptProvider options={initialOptions}>
                  <PayPalButtons
                    style={{
                      shape: "rect",
                      layout: "vertical",

                    }}
                    createSubscription={async (data, actions) => {
                      return actions.subscription.create({
                        'plan_id': 'P-45F68345HM924710MMWU2EGI'
                      });
                    }}
                    onApprove={async (data, actions) => {
                      /*
                        No need to activate manually since SUBSCRIBE_NOW is being used.
                        Learn how to handle other user actions from our docs:
                        https://developer.paypal.com/docs/api/subscriptions/v1/#subscriptions_create
                      */
                      if (data.orderID) {
                        console.log(
                          `You have successfully subscribed to the plan. Your subscription id is: ${data.subscriptionID}`,
                        );
                        storeSubscriptionId(data.subscriptionID)
                      } else {
                        console.log(
                          `Failed to activate the subscription: ${data.subscriptionID}`,
                        );
                      }
                    }}
                  />
                </PayPalScriptProvider>
              </Grid>
            </Grid>
          </Paper>)}
          <Grid container style={{ paddingTop: '20px' }} item xs={12} spacing={2}>
            {finances.expenses.length > 0 && !accessToken &&
              <Grid item xs={12}>
                <Alert icon={false} severity='warning'>
                  {
                    client && userDetails && userDetails != 'login' ? <IconButton>
                      <Avatar alt="Remy Sharp" onClick={handleUserClick} src={userDetails.photos[0].url} />
                    </IconButton> : <button onClick={() => {
                      client.requestAccessToken()
                    }} class="gsi-material-button">
                      <div class="gsi-material-button-state"></div>
                      <div class="gsi-material-button-content-wrapper">
                        <div class="gsi-material-button-icon">
                          <svg version="1.1" viewBox="0 0 48 48" style={{ display: 'block' }}>
                            <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
                            <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
                            <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
                            <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path>
                            <path fill="none" d="M0 0h48v48H0z"></path>
                          </svg>
                        </div>
                        <span class="gsi-material-button-contents">Sign in</span>
                        <span style={{ display: 'none' }}>Sign in with Google</span>
                      </div>
                    </button>
                  } to save your Financial Plan
                </Alert>
              </Grid>
            }
            <ErrorBoundary onError={(e) => console.log(e)} fallback={<Grid container item direction={'row'} justifyContent={'center'} alignItems={'center'}>
              <Grid item>
                <Button variant='outlined' href='/'>Help!</Button>
              </Grid>
            </Grid>}>
              <Grid item xs={12}><Outlet key={scenario} /></Grid>
            </ErrorBoundary>
          </Grid>

        </>

      </Container>

    </>
  )



}

export default App;
