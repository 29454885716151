import '../App.css';
import Stepper from '@mui/material/Stepper'
import { AppBar, Container, Paper, Step, StepLabel, Toolbar, Typography, Grid, TextField, Button, Input, InputAdornment, InputLabel, TableContainer, TableBody, TableCell, Table, TableHead, TableRow, Alert, List, ListItem, ListItemAvatar, Avatar, ListItemText, ListItemIcon, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Select, MenuItem, Box, Tabs, Tab, duration } from '@mui/material';
import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { calculateSavings, finances, getAnnualIncome, getExpense, getNetAnnualIncome, getSavingsAnnualTotal, saveJson } from '../store/store';
import { MoneyFormat, MoneyTextFormat, PercentageFormat } from '../money';
import ImageIcon from '@mui/icons-material/Image';
import HouseIcon from '@mui/icons-material/House';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import CurrencyPoundIcon from '@mui/icons-material/CurrencyPound';
import BuildIcon from '@mui/icons-material/Build';
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import MonthlyAnnualAmmount from '../MonthlyAnnualAmount';
import { ExpenseMap } from '../expenses/Expense';
import { useFinances, useFinancesDispatch } from '../store/FinancesContext';
import FormHelperText from '@mui/material/FormHelperText';

import SavingsIcon from '@mui/icons-material/Savings';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers';

function Collect({ setDone }) {

  const [activeStep, setActiveStep] = React.useState(0);
  const [housingType, setHousingType] = React.useState('rent')
  const [hasPartner, setHasPartner] = React.useState(false)

  var navigate = useNavigate()

  var finances = useFinances()
  var financesDispatch = useFinancesDispatch()

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  var net = finances.income.length > 0 ? getAnnualIncome(finances, 2024) : null
  var remaining = net ? net.net - 16000 : 0


  if (finances.income.length == 0) {
    var firstincome = {
      name: "Job",
      from: 'employer',
      id: "job",
      who: 'you',
      taxable: true,
      inflate: true,
      pension: {
        type: 'definedcontribution',
        lumpSum: 0.25,
        takePensionAge: 65
      },
      periods: [
        {
          start: '2024-01-01',
          end: null,
          gross: 28000,
          employeeContribution: 0.05,
          employerContribution: 0.05
        }
      ]
    }
    financesDispatch({
      type: 'reload',
      item: {
        income: [firstincome],
        expenses: [],
        savings: [],
        credit: [],
        loans: [{
          name: 'Mortgage',
          id: 'mortgage',
          type: 'mortgage',
          duration: 300,
          periods: [
            {
              addition: 0,
              apr: 0.05,
              start: dayjs().month(0).format('YYYY-MM-DD'),
              startAmount: (firstincome.periods[0].gross + 28000) * 4,
              end: null
            }
          ]
        }],
      }
    })

  } else if (finances.income.length > 0 && finances.savings.length == 0) {

    financesDispatch({
      type: 'add',
      financeType: 'savings',
      item: {
        name: 'ISA',
        id: 'ISA',
        type: 'isa',
        periods: [
          {
            start: '2024-01-01',
            end: null,
            amount: 0,
            percent: 0.15,
            interest: 0.07
          }
        ]

      }
    })

    financesDispatch({
      type: 'add',
      financeType: 'savings',
      item: {
        name: 'Savings',
        type: 'general',
        id: 'savings',
        periods: [
          {
            start: '2024-01-01',
            end: null,
            amount: 0,
            percent: 0.08,
            interest: 0.05
          }
        ]

      }
    })

    financesDispatch({
      type: 'add',
      financeType: 'savings',
      item: {
        name: 'Emergency Fund',
        id: 'emergencyfund',
        type: 'emergencyfund',
        periods: [
          {
            start: '2024-01-01',
            end: null,
            amount: 0,
            monthly: 0,
            interest: 0.05
          }
        ]
      }
    })
  }

  // 1100
  // 20% - fun
  // 35% - savings
  // 45% - mortgage


  var updateExp = (value, id, name) => {
    var exp = {
      id: id,
      name: name,
      inflate: true,
      type: id,
      periods: [
        {
          start: '2024-01-01',
          pay: id === 'holiday' ? 'savings' : 'net',
          end: null,
          amount: parseFloat(value)
        }
      ]

    }

    if (finances.expenses.find((f) => f.id == id)) {
      financesDispatch({
        type: 'update',
        financeType: 'expenses',
        item: exp
      })
    } else {
      financesDispatch({
        type: 'add',
        financeType: 'expenses',
        item: exp
      })
    }

  }

  var isaTot = finances.savings[0] ? calculateSavings(finances, finances.savings[0], false) : 0
  var savingsTot = finances.savings[1] ? calculateSavings(finances, finances.savings[1], false) : 0

  return (
    <>

      <Grid container item spacing={2}>
        <Grid item xs={12}>

          <Paper style={{ padding: '20px' }}>
            {activeStep == 0 &&
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography paragraph>How much do you earn before tax each year?</Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField InputProps={{
                    inputComponent: MoneyFormat,
                    startAdornment: <InputAdornment position="start">£</InputAdornment>,
                    endAdornment: <InputAdornment position='end'>per year</InputAdornment>,

                  }} fullWidth id="outlined-basic" key={`amoun`} onChange={(e) => {
                    financesDispatch({
                      type: 'update',
                      financeType: 'income',
                      item: {
                        ...finances.income[0],
                        periods: finances.income[0].periods.map((m) => {
                          m.gross = parseFloat(e.target.value)
                          return m
                        })

                      }
                    })

                  }} value={finances.income[0]?.periods[0].gross} variant="standard" />
                </Grid>
                <Grid item xs={12}>
                  <Typography paragraph>Did you know that over a lifetime, you could:</Typography>
                </Grid>
                <List>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar sx={{ bgcolor: '#1976d2' }}>
                        <HouseIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={(
                      <>
                        Put <b><MoneyTextFormat value={((finances.income[0]?.periods[0].gross) * 4)} /></b> towards a house.
                      </>
                    )} />
                  </ListItem>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar sx={{ bgcolor: '#1976d2' }}>
                        <AirplanemodeActiveIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={(
                      <>
                        Spend up to <b><MoneyTextFormat value={Math.round(savingsTot[0]?.total)} /></b> each year on holidays, home improvements, etc.
                      </>
                    )} />
                  </ListItem>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar sx={{ bgcolor: '#1976d2' }}>
                        <EmojiPeopleIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={(
                      <>
                        Still have <b><MoneyTextFormat value={(Math.round((remaining * 0.2) / 12))} /></b> left at the end of the month to spend on whatever you want
                      </>
                    )} />
                  </ListItem>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar sx={{ bgcolor: '#1976d2' }}>
                        <BuildIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={(
                      <>
                        Keep <b><MoneyTextFormat value={(11000 / 4) * 2} /></b> aside to cover for emergencies
                      </>
                    )} />
                  </ListItem>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar sx={{ bgcolor: '#1976d2' }}>
                        <SavingsIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={(
                      <>
                        Have earnt <b><MoneyTextFormat value={Math.round(isaTot[30]?.total)} /></b> in an ISA come retirement age
                      </>
                    )} />
                  </ListItem>


                  <ListItem>
                    <ListItemAvatar>
                      <Avatar sx={{ bgcolor: '#1976d2' }}>
                        <WbSunnyIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={(
                      <>
                        Have an individual pension worth <b><MoneyTextFormat value={(finances.income[0]?.periods[0].gross * 0.5) + 10000} /></b> a year
                      </>
                    )} />
                  </ListItem>

                </List>
                <Grid item xs={12}>
                  <Typography paragraph>You don't need to be a millionaire to have a Financial Plan. Continue to make the most out of your money.</Typography>
                </Grid>
              </Grid>

            }
            {activeStep == 1 &&
              <Grid container spacing={2}>
                <Grid item container xs={12}>
                  <Paper style={{ width: '100%', padding: '20px' }}>
                    <Grid container item xs={12} spacing={2}>
                      <Grid item xs={12}>
                        <Typography paragraph>Do you have a partner who's income you wish to add to your financial plan?</Typography>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                          <Tabs value={hasPartner ? 0 : 1} variant='fullWidth' aria-label="basic tabs example">
                            <Tab label="Yes" onClick={() => setHasPartner(true)} />
                            <Tab label="No" onClick={() => setHasPartner(false)} />
                          </Tabs>
                        </Box>
                      </Grid>
                      {hasPartner &&
                        <Grid item xs={12}>
                          <TextField InputProps={{
                            inputComponent: MoneyFormat,
                            startAdornment: <InputAdornment position="start">£</InputAdornment>,
                            endAdornment: <InputAdornment position='end'>per year</InputAdornment>,

                          }} fullWidth id="outlined-basic" value={finances.income[1].periods[0].gross} onChange={(e) => {
                            financesDispatch({
                              type: 'update',
                              financeType: 'income',
                              item: {
                                ...finances.income[1],
                                periods: finances.income[1].periods.map((m) => {
                                  m.gross = parseInt(e.target.value)
                                  return m
                                })
                              }
                            })
                          }} variant="outlined" />
                        </Grid>
                      }
                    </Grid>
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <Alert severity='info'>If you receive any other forms of income such as benefits, you can add these later.</Alert>
                </Grid>
                <Grid item xs={12}>
                  <Typography paragraph>Tell us a little about your outgoings. You can add further expenses, loans, credit and savings later.</Typography>
                </Grid>
                <Grid item container xs={12}>
                  <Paper style={{ width: '100%', padding: '20px' }}>
                    <Grid container item xs={12} spacing={2}>
                      <Grid item xs={12}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                          <Tabs value={housingType == 'mortgage' ? 0 : 1} variant='fullWidth' aria-label="basic tabs example">
                            <Tab label="Mortgage" onClick={() => setHousingType('mortgage')} />
                            <Tab label="Rent" onClick={() => setHousingType('rent')} />
                          </Tabs>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>

                        {housingType == 'rent' && (
                          <>
                            <MonthlyAnnualAmmount monthly label={ExpenseMap['rent']} value={finances.expenses.find((f) => f.id == 'rent') ? finances.expenses.find((f) => f.id == 'rent').periods[0].amount : 0} update={(e) => updateExp(e, 'rent')} />
                          </>
                        )}
                        {housingType == 'mortgage' && (
                          <>
                            <Grid container item spacing={2}>
                              <Grid container item spacing={2} xs={12}>
                                <Grid item xs={6}>
                                  <InputLabel htmlFor="standard-adornment-amount">Start</InputLabel>
                                  <DatePicker key={`mortgage-start`}
                                    views={['month', 'year']}
                                    format="MM/YYYY"
                                    disabled={true}
                                    slotProps={{ textField: { fullWidth: true }, actionBar: { actions: ['clear'] } }}
                                    value={dayjs().month(0)}
                                    onChange={(e) => {
                                      financesDispatch({
                                        type: 'update',
                                        financeType: 'loans',
                                        item: {
                                          ...finances.loans[0],
                                          periods: finances.loans[0].periods.map((m) => {
                                            m.start = e ? e.format('YYYY-MM-DD') : null
                                            m.end = e ? e.add(25, 'years').format('YYYY-MM-DD') : null
                                            return m
                                          })
                                        }
                                      })
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <InputLabel htmlFor="standard-adornment-amount">End</InputLabel>
                                  <DatePicker key={`mortgage-start`}
                                    value={finances.loans[0].periods[0].end ? dayjs(finances.loans[0].periods[0].end) : null}
                                    views={['month', 'year']}
                                    format="MM/YYYY"
                                    minDate={dayjs().month(1)}
                                    slotProps={{ textField: { fullWidth: true }, actionBar: { actions: ['clear'] } }}
                                    onChange={(e) => {
                                      financesDispatch({
                                        type: 'update',
                                        financeType: 'loans',
                                        item: {
                                          ...finances.loans[0],
                                          periods: finances.loans[0].periods.map((m) => {
                                            m.end = e ? e.format('YYYY-MM-DD') : null
                                            return m
                                          })
                                        }
                                      })
                                    }}
                                  />
                                </Grid>
                              </Grid>
                              <Grid container item spacing={2}>
                                <Grid item xs={6}>
                                  <InputLabel htmlFor="standard-adornment-amount">Starting Amount</InputLabel>
                                  <TextField helperText="Enter the amount as of the start date" InputProps={{
                                    inputComponent: MoneyFormat,
                                    startAdornment: <InputAdornment position="start">£</InputAdornment>,

                                  }} variant='standard' fullWidth id="outlined-basic" key={`amount}`} onChange={(e) => {

                                    financesDispatch({
                                      type: 'update',
                                      financeType: 'loans',
                                      item: {
                                        ...finances.loans[0],
                                        periods: finances.loans[0].periods.map((m) => {
                                          m.startAmount = parseInt(e.target.value)
                                          return m
                                        })
                                      }
                                    })
                                  }} defaultValue={finances.loans[0].periods[0].startAmount} />
                                </Grid>
                                <Grid item xs={6}>
                                  <InputLabel htmlFor="standard-adornment-amount">Interest (APR)</InputLabel>
                                  <TextField InputProps={{
                                    inputComponent: PercentageFormat,
                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                  }} variant='standard' fullWidth id="outlined-basic" key={`amount}`} onChange={(e) => {
                                    financesDispatch({
                                      type: 'update',
                                      financeType: 'loans',
                                      item: {
                                        ...finances.loans[0],
                                        periods: finances.loans[0].periods.map((m) => {
                                          m.apr = parseFloat(e.target.value) / 100
                                          return m
                                        })
                                      }
                                    })
                                  }} value={finances.loans[0].periods[0].apr * 100} />
                                </Grid>
                                <Grid item xs={12}>
                                  <Alert severity='info'>You can add additional mortgages (and other loans), should you have them, later.</Alert>
                                </Grid>
                              </Grid>
                            </Grid>
                          </>
                        )}
                      </Grid>
                    </Grid>
                  </Paper>

                </Grid>
                <Grid item xs={12}>
                  <MonthlyAnnualAmmount monthly label={ExpenseMap['childcare']} value={finances.expenses.find((f) => f.id == 'childcare') ? finances.expenses.find((f) => f.id == 'childcare').periods[0].amount : 0} update={(e) => updateExp(e, 'childcare')} />
                </Grid>
                <Grid item xs={12}>
                  <MonthlyAnnualAmmount monthly label={ExpenseMap['energy']} value={finances.expenses.find((f) => f.id == 'energy') ? finances.expenses.find((f) => f.id == 'energy').periods[0].amount : 0} update={(e) => updateExp(e, 'energy')} />
                </Grid>
                <Grid item xs={12}>
                  <MonthlyAnnualAmmount monthly label={ExpenseMap['counciltax']} value={finances.expenses.find((f) => f.id == 'counciltax') ? finances.expenses.find((f) => f.id == 'counciltax').periods[0].amount : 0} update={(e) => updateExp(e, 'counciltax')} />
                </Grid>
                <Grid item xs={12}>
                  <MonthlyAnnualAmmount monthly label={ExpenseMap['water']} value={finances.expenses.find((f) => f.id == 'water') ? finances.expenses.find((f) => f.id == 'water').periods[0].amount : 0} update={(e) => updateExp(e, 'water')} />
                </Grid>
                <Grid item xs={12}>
                  <MonthlyAnnualAmmount monthly label={ExpenseMap['phone']} value={finances.expenses.find((f) => f.id == 'phone') ? finances.expenses.find((f) => f.id == 'phone').periods[0].amount : 0} update={(e) => updateExp(e, 'phone')} />
                </Grid>
                <Grid item xs={12}>
                  <MonthlyAnnualAmmount monthly label={ExpenseMap['broadband']} value={finances.expenses.find((f) => f.id == 'broadband') ? finances.expenses.find((f) => f.id == 'broadband').periods[0].amount : 0} update={(e) => updateExp(e, 'broadband')} />
                </Grid>
                <Grid item xs={12}>
                  <MonthlyAnnualAmmount monthly label={ExpenseMap['transportation']} value={finances.expenses.find((f) => f.id == 'transportation') ? finances.expenses.find((f) => f.id == 'transportation').periods[0].amount : 0} update={(e) => updateExp(e, 'transportation')} />
                </Grid>
                <Grid item xs={12}>
                  <MonthlyAnnualAmmount monthly label={ExpenseMap['fuel']} value={finances.expenses.find((f) => f.id == 'fuel') ? finances.expenses.find((f) => f.id == 'fuel').periods[0].amount : 0} update={(e) => updateExp(e, 'fuel')} />
                </Grid>
                <Grid item xs={12}>
                  <MonthlyAnnualAmmount monthly label={ExpenseMap['carinsurance']} value={finances.expenses.find((f) => f.id == 'carinsurance') ? finances.expenses.find((f) => f.id == 'carinsurance').periods[0].amount : 0} update={(e) => updateExp(e, 'carinsurance')} />
                </Grid>
                <Grid item xs={12}>
                  <MonthlyAnnualAmmount monthly label={ExpenseMap['holiday']} value={finances.expenses.find((f) => f.id == 'holiday') ? finances.expenses.find((f) => f.id == 'holiday').periods[0].amount : 0} update={(e) => updateExp(e, 'holiday')} />
                </Grid>
                <Grid item xs={12}>
                  <MonthlyAnnualAmmount monthly label={ExpenseMap['shopping']} value={finances.expenses.find((f) => f.id == 'shopping') ? finances.expenses.find((f) => f.id == 'shopping').periods[0].amount : 0} update={(e) => updateExp(e, 'shopping')} />
                </Grid>
                <Grid item xs={12}>
                  <MonthlyAnnualAmmount monthly label={ExpenseMap['homeinsurance']} value={finances.expenses.find((f) => f.id == 'homeinsurance') ? finances.expenses.find((f) => f.id == 'homeinsurance').periods[0].amount : 0} update={(e) => updateExp(e, 'homeinsurance')} />
                </Grid>


              </Grid>
            }
          </Paper>


        </Grid>

        <Grid container item justifyContent="flex-end" xs={12}>
          <Button
            color="inherit"
            disabled={activeStep === 0}
            onClick={handleBack}
            sx={{ mr: 1 }}
          >
            Back
          </Button>
          {activeStep < 1 &&
            <Button onClick={() => {
              handleNext()
              financesDispatch({
                type: 'add',
                financeType: 'income',
                item: {
                  name: "Partner",
                  from: 'employer',
                  id: "partner",
                  who: 'partner',
                  taxable: true,
                  inflate: true,
                  pension: {
                    type: 'definedcontribution',
                    lumpSum: 0.25,
                    takePensionAge: 65
                  },
                  periods: [
                    {
                      start: '2024-01-01',
                      end: null,
                      gross: 28000,
                      employeeContribution: 0.05,
                      employerContribution: 0.05
                    }
                  ]
                }
              })
            }}>NEXT</Button>
          }
          {activeStep == 1 &&
            <Button onClick={() => {
              if (finances.expenses.find((f) => f.type == 'rent')) {
                financesDispatch({
                  type: 'delete',
                  financeType: 'loans',
                  id: 'mortgage'
                })

              } else {
                financesDispatch({
                  type: 'update',
                  financeType: 'loans',
                  id: 'mortgage',
                  item: {
                    ...finances.loans[0],
                    duration: finances.loans[0].periods[0].end ? dayjs(finances.loans[0].periods[0].end, 'YYYY-MM-DD').diff(dayjs(finances.loans[0].periods[0].start, 'YYYY-MM-DD'), 'months') : finances.loans[0].duration
                  }
                })
              }

              if (!hasPartner) {
                financesDispatch({
                  type: 'delete',
                  financeType: 'income',
                  id: finances.income[1].id
                })
              }



              navigate("/annual/2024")

            }}>FINISH</Button>
          }
        </Grid>
      </Grid >
    </>

  );
}

export default Collect;
