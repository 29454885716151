import { FormControl, Grid, InputAdornment, InputLabel, MenuItem, Select, TextField } from "@mui/material"
import { MoneyFormat } from "./money"
import React from "react"
import { ExpenseMap } from "./expenses/Expense"


function MonthlyAnnualAmmount(props) {

    var [annual, setAnnual] = React.useState(props.monthly ? false : true)
    var [value, setValue] = React.useState(props.value)

    return (
        <Grid container xs={12}>
            <Grid item xs={12}>
                <InputLabel htmlFor="standard-adornment-amount">{props.label}</InputLabel>
            </Grid>
            <Grid item component={FormControl} variant='outlined' container xs={12}>
                <Grid item xs={12}>
                    <TextField InputProps={{
                        inputComponent: MoneyFormat,
                        startAdornment: <InputAdornment position="start">£</InputAdornment>,
                        sx: {paddingRight: 0},  
                        endAdornment: <InputAdornment position="end">
                            <Select
                                sx={{ backgroundColor: '#f3f3f3', width: '120px' }}
                                value={annual}
                                onChange={(e) => {
                                    if (annual) {
                                        setValue(value / 12)
                                    } else {
                                        setValue(value * 12)
                                    }

                                    setAnnual(!annual)
                                }}
                                
                            >
                                <MenuItem value={false}>Monthly</MenuItem>
                                <MenuItem value={true}>Yearly</MenuItem>
                            </Select>
                        </InputAdornment>
                    }} disabled={props.disabled} fullWidth value={value ? (annual ? props.value : props.value / 12) : annual ? props.value : props.value / 12} onChange={(e) => {
                        props.update(annual ? parseFloat(e.target.value) : parseFloat(e.target.value) * 12)
                        setValue(parseFloat(e.target.value))
                    }} />
                </Grid>
            </Grid>
        </Grid>
    )
}

export default MonthlyAnnualAmmount