import * as React from 'react';
import { NumericFormat } from 'react-number-format';
import PropTypes from 'prop-types';
import { defaults } from 'chart.js';


const MoneyFormat = React.forwardRef(function NumericFormatCustom(
  props,
  ref,
) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      valueIsNumericString
      decimalScale={2}
    />
  );
});

const PercentageFormat = React.forwardRef(function NumericFormatCustom(
  props,
  ref,
) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      valueIsNumericString
      suffix='%'
      decimalScale={2}
    />
  );
});

const MoneyTextFormat = React.forwardRef(function NumericFormatCustom(
  props,
  ref,
) {
  const { onChange, ...other } = props;
  
  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      displayType='text'
      thousandSeparator
      valueIsNumericString
      prefix="£"
      decimalScale={0}
    />
  );
});

const PercentTextFormat = React.forwardRef(function NumericFormatCustom(
  props,
  ref,
) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      displayType='text'
      suffix='%'
      decimalScale={2}
    />
  );
});

export {
  MoneyFormat,
  PercentageFormat,
  MoneyTextFormat,
  PercentTextFormat
}