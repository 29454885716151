import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import store from './store/store';

import { StyledEngineProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import { Link, Navigate, RouterProvider, ScrollRestoration, createBrowserRouter } from "react-router-dom"
import Collect from './welcome/Collect';
import Outlook from './planner/Outlook';
import LoanPayment from './loan/LoanPayment';
import Expense from './expenses/Expense';
import Pension from './pension/Pension';
import CreditPayment from './credit/CreditPayment';
import Annual from './annual/Annual';
import Savings from './savings/Savings';
import Income from './income/Income';
import Tax from './income/Tax';
import ScrollToTop from './ScrollToTop';
import { FinancesProvider } from './store/FinancesContext';
import dayjs from 'dayjs';
import Privacy from './privacy';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/",
        element: <Collect />,
      },
      {
        path: "privacy",
        element: <Privacy />
      },
      {
        path: "annual/:year",
        element: <Annual />
      },
      {
        path: "tax/:year",
        element: <Tax />
      },
      {
        path: "outlook",
        element: <Outlook />
      },
      {
        path: "loan",
        element: <LoanPayment />
      },
      {
        path: "loan/:loanId",
        element: <LoanPayment />
      },
      {
        path: "income",
        element: <Income />
      },
      {
        path: "income/:incomeId",
        element: <Income />
      },
      {
        path: "expense",
        element: <Expense />
      },
      {
        path: "expense/:expenseId",
        element: <Expense />
      },
      {
        path: "pension/:who?/:pension?/:pensionPeriod?",
        element: <Pension />
      },
      {
        path: "credit",
        element: <CreditPayment />
      },
      {
        path: "credit/:creditId",
        element: <CreditPayment />
      },
      {
        path: "savings",
        element: <Savings />
      },
      {
        path: "savings/:savingsId",
        element: <Savings />
      }

    ]
  }

])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <FinancesProvider>
        <StyledEngineProvider injectFirst>
          <RouterProvider router={router}>
            <App />
          </RouterProvider>
        </StyledEngineProvider>
      </FinancesProvider>
    </LocalizationProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
