import { FormControl, Grid, InputAdornment, InputLabel, MenuItem, Select, TextField } from "@mui/material"
import { MoneyFormat } from "./money"
import React from "react"


function AmountPercent(props) {

    var [percent, setPercent] = React.useState(props.percent != null)
    var [value, setValue] = React.useState(props.percent ? props.percent*100 : props.amount)

    return (
        <Grid container xs={12}>
            <Grid item xs={12}>
                <InputLabel htmlFor="standard-adornment-amount">{props.label}</InputLabel>
            </Grid>
            <Grid item component={FormControl} variant='outlined' container xs={12}>
                <Grid item xs={12}>
                    <TextField InputProps={{
                        inputComponent: MoneyFormat,
                        startAdornment: !percent && <InputAdornment position="start">£</InputAdornment>,
                        sx: { paddingRight: 0 },
                        endAdornment: <InputAdornment position="end">
                            <Select
                                sx={{ backgroundColor: '#f3f3f3', width: '120px' }}
                                value={percent == true}
                                onChange={(e) => {
                                    if (percent) {
                                        props.setPercent(parseFloat(e.target.value)/100)
                                    } else {
                                        props.setAmount(parseFloat(e.target.value))
                                    }

                                    setPercent(!percent)
                                }}

                            >
                                <MenuItem value={false}>£</MenuItem>
                                <MenuItem value={true}>%</MenuItem>
                            </Select>
                        </InputAdornment>
                    }} fullWidth value={value} onChange={(e) => {
                        setValue(parseFloat(e.target.value))
                        if (percent) {
                            props.setPercent(parseFloat(e.target.value)/100)
                        } else {
                            props.setAmount(parseFloat(e.target.value))
                        }
                    }} />
                </Grid>
            </Grid>
        </Grid>
    )
}

export default AmountPercent