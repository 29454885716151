import { Card, CardContent, Container, Paper, Step, StepLabel, Toolbar, Typography, Grid, TextField, Button, TableContainer, TableBody, TableRow, Table, TableHead, TableCell, CardMedia, CardActions, Select, MenuItem, InputLabel, Input, Slider, CardHeader, InputAdornment, Breadcrumbs, Accordion, AccordionSummary, AccordionDetails, styled, containerClasses, Modal, Dialog, DialogTitle, DialogContent, DialogContentText, IconButton, Alert, Checkbox, FormControl, FormControlLabel } from '@mui/material';
import React, { useState } from 'react';
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'

import { Link, RouterProvider, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';

import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';

import InfoIcon from '@mui/icons-material/Info';

import MuiAccordionSummary, {
    AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import Info from '@mui/icons-material/Info';
import { calculateRepayments, createExpense, finances, getCredit, getExpense, updateCredit, updateExpense } from '../store/store';
import { MoneyFormat, MoneyTextFormat } from '../money';
import MonthlyAnnualAmmount from '../MonthlyAnnualAmount';
import ListSubheader from '@mui/material/ListSubheader';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { useFinances, useFinancesDispatch } from '../store/FinancesContext';
import DeleteDialog from '../deletedialog';
import { DataGrid } from '@mui/x-data-grid';
import Period from '../periods/Period';
import { flushSync } from 'react-dom';


export const ExpenseMap = {
    'rent': 'Rent',
    'energy': 'Energy',
    'counciltax': 'Council Tax',
    'water': 'Water',
    'phone': 'Phone',
    'broadband': 'Broadband & TV',
    'fuel': 'Car Fuel',
    'carinsurance': 'Car Insurance',
    'carmaintenance': 'Car Maintenance',
    'lifeinsurance': 'Life Insurance',
    'childcare': 'Childcare',
    'dental': 'Dental',
    'holiday': 'Holiday',
    'shopping': 'Shopping',
    'homeinsurance': 'Home Insurance',
    'christmas': 'Christmas',
    'healthinsurnace': 'Health Insurance',
    'transportation': 'Transportation',
    'subscriptions': 'Subscriptions',
    'other': 'Other',
    'clothing': 'Clothing',
    'healthbeauty': 'Health & Beatuty'
}

function Expense() {
    var { expenseId } = useParams()
    var year = useSearchParams()[0].get('year')

    const [showDelete, setShowDelete] = React.useState()

    var navigate = useNavigate()

    var finances = useFinances()
    var financesDispatch = useFinancesDispatch()
    var [selectedPeriod, setSelectedPeriod] = useState(null)

    var exp = finances.expenses.find((f) => f.id == expenseId)

    var [expense, setExpense] = useState(exp ? exp : {
        name: '',
        inflate: true,
        periods: [
            {
                pay: 'net',
                start: null,
                end: null,
                amount: null
            }
        ]

    })

    console.debug("Expense state=", expense)

    expense.periods.sort((a, b) => {
        if (a.start == null || b.start == null) {
            return 1
        }

        return a.start - b.start
    })

    if (!selectedPeriod) {
        if (year) {
            setSelectedPeriod(expense.periods[expense.periods.findIndex((f) => year == f.start)])
        } else {
            setSelectedPeriod(expense.periods[expense.periods.length - 1])
        }

    }

    return (
        <>
            {showDelete &&
                <DeleteDialog delete={showDelete.delete} name={showDelete.name} handleClose={() => setShowDelete(null)} />
            }
            <Grid container spacing={2}>
                <Grid container item xs={12}>
                    <Paper style={{ width: '100%', padding: '20px' }}>
                        <Grid item xs={12} container spacing={2}>
                            <Grid item xs={12}>
                                <InputLabel htmlFor="standard-adornment-amount">Type</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    key={`start-${expense.name}`}
                                    value={expense.type}
                                    onChange={(e) => {
                                        var existngExpense = !e.target.value != 'other' && finances.expenses.find((f) => f.id == e.target.value)
                                        if (existngExpense) {
                                            setExpense(existngExpense)
                                        } else {
                                            setExpense({
                                                name: e.target.value != 'other' ? e.target.value : '',
                                                inflate: true,
                                                type: e.target.value,
                                                periods: [
                                                    {
                                                        pay: 'net',
                                                        start: null,
                                                        end: null,
                                                        amount: null
                                                    }
                                                ]

                                            })
                                        }

                                        setSelectedPeriod(null)
                                    }}
                                    label="Age"
                                    fullWidth
                                >
                                    {
                                        Object.keys(ExpenseMap).filter((f) => !finances.expenses.find((e) => e.type == f)).map((m) => {
                                            return <MenuItem value={m}>{ExpenseMap[m]}</MenuItem>
                                        }).sort((a, b) => {
                                            return a.props.children.localeCompare(b.props.children)
                                        })
                                    }
                                    <MenuItem value={'other'}>Other</MenuItem>
                                    <ListSubheader>Existing Expenses</ListSubheader>
                                    {
                                        Object.keys(ExpenseMap).filter((f) => finances.expenses.find((e) => e.type == f)).map((m) => {
                                            return <MenuItem value={m}>{ExpenseMap[m]}</MenuItem>
                                        }).sort((a, b) => {
                                            return a.props.children.localeCompare(b.props.children)
                                        })
                                    }

                                </Select>
                            </Grid>
                            {
                                expense.type == 'other' && <Grid item xs={12}>
                                    <InputLabel htmlFor="standard-adornment-amount">Name</InputLabel>
                                    <TextField fullWidth id="outlined-basic" defaultValue={expense.customname} onChange={(e) => { setExpense({ ...expense, customname: e.target.value }) }} />
                                </Grid>
                            }
                            <Grid item xs={12}>
                                <FormControlLabel control={<Checkbox checked={expense.inflate} onChange={(e) => {
                                    setExpense({
                                        ...expense,
                                        inflate: !expense.inflate
                                    })
                                }} />} label="Rise with Inflation" />
                            </Grid>
                        </Grid>


                    </Paper>
                </Grid>
                <Period monthly={true} editLink={(selYear) => {
                    var p = selYear && expense.periods.find((f) => selYear.format('YYYY-MM-DD') == f.start)
                    setSelectedPeriod(p ? p : expense.periods[expense.periods.length - 1])
                }} periods={expense.periods} selectedPeriod={selectedPeriod} update={() => {
                    setExpense({
                        ...expense
                    })
                }} getAmount={(period) => {
                    return period.amount
                }} addPeriod={(periods) => {
                    periods.push({
                        pay: 'net',
                        start: null,
                        end: null,
                        amount: null
                    })
                }}>

                    <Grid item xs={12}>
                        <InputLabel htmlFor="standard-adornment-amount">Pay From</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            key={`start-${selectedPeriod?.start}`}
                            value={selectedPeriod?.pay}
                            defaultValue={'net'}
                            onChange={(e) => {
                                setExpense({
                                    ...expense,
                                    periods: expense.periods.map((m) => {
                                        if (m.start == selectedPeriod?.start) {
                                            m.pay = e.target.value
                                        }

                                        return m
                                    })
                                })
                                //setSelectedPeriodId(e.target.value)
                            }}
                            label="Age"
                            fullWidth
                        >
                            <MenuItem value="net">Net Pay</MenuItem>
                            <ListSubheader>Savings & Investments</ListSubheader>
                            {
                                finances.savings.map((m) => {
                                    return <MenuItem value={m.id}>{m.name}</MenuItem>
                                })
                            }
                            <ListSubheader>Credit</ListSubheader>
                            {
                                finances.credit.map((m) => {
                                    return <MenuItem value={m.id}>{m.name}</MenuItem>
                                })
                            }
                        </Select>
                    </Grid>
                    <Grid container item spacing={2} xs={12}>


                        <Grid container spacing={2} item xs={12}>
                            <Grid item xs={12}>
                                <MonthlyAnnualAmmount key={`amount-${selectedPeriod?.start}`} label='Amount' value={selectedPeriod?.amount} update={(e) => {
                                    setExpense({
                                        ...expense,
                                        periods: expense.periods.map((m) => {
                                            if (m.start == selectedPeriod?.start) {
                                                m.amount = parseFloat(e)
                                            }

                                            return m
                                        })
                                    })
                                }} />
                            </Grid>

                        </Grid>
                        <Grid item container xs={12}>
                            <InputLabel htmlFor="standard-adornment-amount">Comments</InputLabel>
                            <TextField
                                id="filled-multiline-flexible"
                                key={`amount-${selectedPeriod?.start}`}
                                defaultValue={selectedPeriod?.comments}
                                onChange={(e) => {
                                    setExpense({
                                        ...expense,
                                        periods: expense.periods.map((m) => {
                                            if (m.start == selectedPeriod?.start) {
                                                m.comments = e.target.value
                                            }

                                            return m
                                        })
                                    })
                                }}
                                fullWidth
                                multiline
                                maxRows={4}
                            />
                        </Grid>


                    </Grid>


                </Period>

                <Grid container item spacing={2} justifyContent='flex-end'>
                    <Grid item>
                        <Button variant='outlined' onClick={(a) => {
                            setShowDelete({
                                delete: () => {
                                    financesDispatch({
                                        type: 'delete',
                                        financeType: 'expenses',
                                        id: expense.id
                                    })
                                    setShowDelete(null)
                                    navigate(-1)
                                },
                                name: expense.type == 'other' ? expense.customname : ExpenseMap[expense.id]
                            })
                        }}>Delete</Button>
                    </Grid>
                    <Grid item>
                        {expense.id ? <Button onClick={() => { financesDispatch({ type: 'update', financeType: 'expenses', item: expense }); navigate(-1) }} variant='contained'>Update Expense</Button> : <Button onClick={() => { financesDispatch({ type: 'add', financeType: 'expenses', item: { ...expense, id: expense.type == 'other' ? expense.customname : expense.type } }); navigate(-1) }} variant='contained'>Add Expense</Button>}
                    </Grid>
                </Grid>



            </Grid >
        </>


    );
}

export default Expense;

