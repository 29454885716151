import '../App.css';
import { Card, CardContent, Container, Paper, Step, StepLabel, Toolbar, Typography, Grid, TextField, Button, TableContainer, TableBody, TableRow, Table, TableHead, TableCell, CardMedia, CardActions, Select, MenuItem, InputLabel, Input, Slider, CardHeader, InputAdornment, LinearProgress, Alert, Divider, IconButton } from '@mui/material';
import React, { useEffect } from 'react';
import EditIcon from '@mui/icons-material/Edit'
import { calculateRepayments, calculateSavings, finances, getAnnualIncome, getIncome, getIncomeAfterExpenses, getIncomes, getNetExpenseAnnualTotal, getSavingsAnnualTotal, getSavingsIncludingPension, settings } from '../store/store';
import { MoneyTextFormat, PercentageFormat } from '../money';
import Carousel from 'react-material-ui-carousel';
import { Link, useNavigate, useParams } from 'react-router-dom';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { useFinances, useFinancesDispatch } from '../store/FinancesContext';

function Pension() {

    var { who, pension, pensionPeriod } = useParams()

    who = who ? who : 'you'

    var finances = useFinances()
    var financesDispatch = useFinancesDispatch()

    var navigate = useNavigate()

    var pensionIncome = []
    var pensionSavings = []
    var selectedPension = null

    var setVals = () => {
        pensionIncome = getIncomes(finances).filter((f) => f.type == 'pension' && f.who == who)
        pensionSavings = getSavingsIncludingPension(finances).filter((f) => f.type == 'pension' && f.who == who)
        selectedPension = pension == null ? pensionSavings[0] : pensionSavings.find((f) => f.id == pension)
    }

    const labels = [];
    for (var i = 2056; i < 2080; i++) {
        labels.push(i)
    }

    setVals()

    var [associatedIncome, setAssociatedIncome] = React.useState(finances.income.find((f) => f.id == selectedPension?.incomeId))
    var [selectedPeriod, setSelectedPeriod] = React.useState(pensionPeriod == null ? associatedIncome?.periods[0] : associatedIncome?.periods.find((f) => f.start == parseInt(pensionPeriod)))

    return (

        <Grid container spacing={4}>
            <Grid container item xs={12} spacing={2}>
                { finances.income.find((f) => f.pension?.type == 'definedbenefit') &&
                    <Grid item xs={12}>
                        <Alert severity='warning'>Defined Benefit pensions will not be shown below. This feature is coming soon.</Alert>
                    </Grid>
                }
                <Grid item xs={12}>
                    <Paper style={{ padding: '20px' }}>
                        <Grid container spacing={2}>
                            <Grid container item xs={12} md={6}>
                                <Grid item xs={12}>
                                    <InputLabel htmlFor="standard-adornment-amount">Who</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={who}
                                        onChange={(e) => {
                                            setAssociatedIncome(null)
                                            setSelectedPeriod(null)
                                            navigate(`/pension/${e.target.value}`)
                                            //setWho(e.target.value)
                                        }}
                                        label="Age"
                                        fullWidth
                                    >
                                        <MenuItem value={'you'}>You</MenuItem>
                                        <MenuItem value={'partner'}>Partner</MenuItem>
                                    </Select>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <InputLabel htmlFor="standard-adornment-amount">Date of Birth</InputLabel>
                                <DatePicker key={`start-${selectedPeriod?.start}`}
                                    value={finances.personal?.dob[who] ? dayjs(finances.personal?.dob[who]) : null}
                                    format='DD/MM/YYYY'
                                    slotProps={{ textField: { fullWidth: true }, actionBar: { actions: ['clear'] } }}
                                    onChange={(e) => {
                                        if (!finances.personal) {
                                            finances.personal = {
                                                dob: {
                                                    you: null,
                                                    who: null
                                                }
                                            }
                                        }
                                        finances.personal.dob[who] = e ? e.format('YYYY-MM-DD') : null

                                        financesDispatch({
                                            type: 'update',
                                            financeType: 'personal',
                                            item: {
                                                ...finances.personal
                                            }
                                        })
                                    }}
                                />
                            </Grid>
                        </Grid>

                    </Paper>

                </Grid>

                <Grid container spacing={2} item xs={12}>

                    <Grid item xs={12} md={6}>
                        <Card style={{ height: '100%' }}>
                            <CardContent>
                                <Typography variant='h6'>Annual Pension</Typography>
                                <Typography variant='h3'>{
                                    <MoneyTextFormat value={pensionIncome.filter((f) => f.subtype != 'lumpsum').reduce((prev, cur) => prev + cur.periods[0].gross, 0)} />
                                }</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Card style={{ height: '100%' }}>
                            <CardContent>
                                <Typography variant='h6'>Lump Sum</Typography>
                                <Typography variant='h3'>{
                                    <MoneyTextFormat value={pensionIncome.filter((f) => f.subtype == 'lumpsum').reduce((prev, cur) => prev + cur.periods[0].gross, 0)} />
                                }</Typography>
                            </CardContent>
                        </Card>
                    </Grid>

                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Divider />
            </Grid>
            <Grid container item xs={12} spacing={2}>
                <Grid item xs={12}>
                    <Typography variant='h5'>State Pension</Typography>
                </Grid>
                <Grid container spacing={2} item xs={12}>
                    <Grid item xs={12}>
                        <Card style={{ height: '100%' }}>
                            <CardContent>
                                <Typography variant='h6'>Annual Pension</Typography>
                                <Typography variant='h3'>{
                                    <MoneyTextFormat value={pensionIncome.find((f) => f.subtype == 'public' && f.who == who).periods[0].gross} />
                                }</Typography>

                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Divider />
            </Grid>
            <Grid container item xs={12} spacing={2}>
                <Grid item xs={12}>
                    <Typography variant='h5'>Workplace Pensions</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Alert severity='info'>Workplace pension performance is based on an average return of 8% with over 20 years until retirement, and 6% within 20 years.</Alert>
                </Grid>
                <Grid container item xs={12} spacing={2}>

                    <Grid item xs={12} md={6}>
                        <Card style={{ height: '100%' }}>
                            <CardContent>
                                <Typography variant='h6'>Annual Pension</Typography>
                                <Typography variant='h3'>{
                                    <MoneyTextFormat value={pensionIncome.filter((f) => f.subtype != 'lumpsum' && f.incomeId).reduce((prev, cur) => prev + cur.periods[0].gross, 0)} />
                                }</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Card style={{ height: '100%' }}>
                            <CardContent>
                                <Typography variant='h6'>Lump Sum</Typography>
                                <Typography variant='h3'>{
                                    <MoneyTextFormat value={pensionIncome.filter((f) => f.subtype == 'lumpsum').reduce((prev, cur) => prev + cur.periods[0].gross, 0)} />
                                }</Typography>
                            </CardContent>
                        </Card>
                    </Grid>

                </Grid>
                <Grid container spacing={2} item xs={12}>
                    <Grid item xs={12}>
                        <TableContainer component={Paper}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Pension</TableCell>
                                        <TableCell>Size</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        pensionSavings.map((p) => {
                                            return <TableRow>
                                                <TableCell>{p.name}</TableCell>
                                                <TableCell><MoneyTextFormat value={(() => {
                                                    var takePensionYear = dayjs(finances.personal.dob[p.who]).add(finances.income.find((f) => f.id == p.incomeId).pension.takePensionAge, 'years').year()
                                                    return calculateSavings(finances, p, false).find((f) => f.year == takePensionYear - 1).total
                                                })()} /></TableCell>
                                                <TableCell align='right'><IconButton component={Link} state={{ scroll: false }} replace={true} to={`/income/${p.incomeId}`}><EditIcon /></IconButton></TableCell>
                                            </TableRow>
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='h5'>Employer Pension (You)</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Paper style={{ padding: '20px' }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <InputLabel htmlFor="standard-adornment-amount">Planned Retirement Age</InputLabel>
                                <Input disabled fullWidth id="outlined-basic" label="Gas & Electric" variant="outlined" value={associatedIncome?.pension.takePensionAge} />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <InputLabel htmlFor="standard-adornment-amount">Lump Sum</InputLabel>
                                <Input disabled fullWidth id="outlined-basic" label="Gas & Electric" variant="outlined" defaultValue={associatedIncome?.pension.lumpSum * 100} endAdornment={<InputAdornment>%</InputAdornment>} />
                            </Grid>
                        </Grid>
                    </Paper>

                </Grid>
                <Grid container spacing={2} item xs={12}>
                    <Grid item xs={12} md={6}>
                        <Card style={{ height: '100%' }}>
                            <CardContent>
                                <Typography variant='h6'>Annual Pension</Typography>
                                <Typography variant='h3'>{
                                    <MoneyTextFormat value={pensionIncome.find((f) => f.subtype != 'lumpsum' && f.incomeId != undefined && f.incomeId == associatedIncome?.id)?.periods[0].gross} />
                                }</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Card style={{ height: '100%' }}>
                            <CardContent>
                                <Typography variant='h6'>Lump Sum</Typography>
                                <Typography variant='h3'>{
                                    <MoneyTextFormat value={pensionIncome.find((f) => f.subtype == 'lumpsum' && f.incomeId == associatedIncome?.id)?.periods[0].gross} />
                                }</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Grid>

        </Grid >

    );
}

export default Pension;
