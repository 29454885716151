import { Card, CardContent, Container, Paper, Step, StepLabel, Toolbar, Typography, Grid, TextField, Button, TableContainer, TableBody, TableRow, Table, TableHead, TableCell, CardMedia, CardActions, Select, MenuItem, InputLabel, Input, Slider, CardHeader, InputAdornment, Breadcrumbs, Accordion, AccordionSummary, AccordionDetails, styled, containerClasses, Modal, Dialog, DialogTitle, DialogContent, DialogContentText, IconButton, Alert } from '@mui/material';
import React, { useState } from 'react';
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'

import { Link, RouterProvider, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';

import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';

import InfoIcon from '@mui/icons-material/Info';

import MuiAccordionSummary, {
    AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import Info from '@mui/icons-material/Info';
import { calculateRepayments, createCredit, deleteExpense, finances, getCredit, updateCredit } from '../store/store';
import { MoneyFormat, MoneyTextFormat, PercentageFormat } from '../money';
import Delete from '@mui/icons-material/Delete';
import DeleteDialog from '../deletedialog';
import { ExpenseMap } from '../expenses/Expense';
import dayjs from 'dayjs';


import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { DatePicker } from '@mui/x-date-pickers';
import { useFinances, useFinancesDispatch } from '../store/FinancesContext';
import { DataGrid } from '@mui/x-data-grid';
import Period from '../periods/Period';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';



function CreditPayment() {
    var { creditId } = useParams()
    var year = useSearchParams()[0].get('year')

    var finances = useFinances()
    var financesDispatch = useFinancesDispatch()
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));

    var [selectedPeriod, setSelectedPeriod] = useState(null)

    var navigate = useNavigate()

    var rloan = finances.credit.find((f) => f.id == creditId)


    var [loan, setLoan] = useState(rloan ? rloan : {
        name: '',
        periods: [
            {
                addition: 0,
                apr: 0,
                start: null,
                startAmount: 0,
                monthly: 0,
                end: null
            }
        ]

    })

    const options = {
        elements: {
            point: {
                backgroundColor: (p) => {
                    return p.raw?.y < 0 ? '#FF0000' : ChartJS.defaults.backgroundColor
                }
            }
        },
        responsive: true,
        plugins: {
            legend: {
                position: 'bottom',
            }
        },
        scales: {
            x: {
                type: 'linear',
                min: dayjs(loan.periods[0].start).year(),
                max: 2070,
                ticks: {
                    stepSize: 10,
                    callback: function (value) {
                        return parseInt(value)
                    }
                }
            }
        }
    };

    const labels = [];
    for (var i = dayjs(loan.periods[0].start).year(); i < 2070 + 1; i++) {
        labels.push(`${i}`)
    }


    loan.periods.sort((a, b) => {
        if (a.start == null || b.start == null) {
            return 1
        }

        return a.start - b.start
    })


    if (!selectedPeriod) {
        if (year) {
            setSelectedPeriod(loan.periods[loan.periods.findIndex((f) => year == f.start)])
        } else {
            setSelectedPeriod(loan.periods[loan.periods.length - 1])
        }

    }

    var repayments = calculateRepayments(finances, loan)
    if (Array.isArray(repayments)) {
        console.debug("Credit Payment repayments", repayments)

        var yearCalc = repayments.find((f) => f.year >= selectedPeriod?.start && (f.year <= selectedPeriod?.end || selectedPeriod?.end == null))

        if (repayments.length > 0) {
            yearCalc = yearCalc ? yearCalc : repayments[repayments.length - 1]
        } else {
            yearCalc = {
                year: year,
                startAmount: 0,
                endAmount: 0,
                interest: 0,
                payments: 0
            }
        }
        console.debug("Credit payment displaying year=", yearCalc)
    }

    const [showDelete, setShowDelete] = React.useState()

    return (
        <>
            {showDelete &&
                <DeleteDialog delete={showDelete.delete} name={showDelete.name} handleClose={() => setShowDelete(null)} />
            }
            <Grid container spacing={2}>
                <Grid container item xs={12}>
                    <Paper style={{ width: '100%', padding: '20px' }}>
                        <Grid item xs={12} container spacing={2}>
                            <Grid item xs={12}>
                                <InputLabel htmlFor="standard-adornment-amount">Name</InputLabel>
                                <TextField variant='standard' fullWidth id="outlined-basic" defaultValue={loan.name} onChange={(e) => { setLoan({ ...loan, name: e.target.value }) }} />
                            </Grid>
                            <Grid container spacing={2} item xs={12}>
                                <Grid item xs={6}>
                                    <Card style={{ height: '100%' }}>
                                        <CardContent>
                                            <Typography variant='h6'>Repayments</Typography>
                                            {
                                                Array.isArray(repayments) && (
                                                    <>
                                                        <Typography variant='h4'><MoneyTextFormat value={Math.round(repayments.reduce((prev, cur) => prev + cur.payments, 0))} /></Typography>
                                                        <Typography color='grey'>Over {repayments.length ? repayments.filter((f) => f.startAmount > 0).length : 0} years</Typography>
                                                    </>
                                                )
                                            }
                                            {
                                                repayments.length > 0 && repayments[repayments.length - 1].endAmount > 0 && <Alert severity='warning'>
                                                    <Grid container item spacing={2} justifyItems="center">
                                                        <Grid item xs={12}>
                                                            It looks like you won't pay back your debt.
                                                        </Grid>

                                                    </Grid>
                                                </Alert>
                                            }

                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={6}>
                                    <Card style={{ height: '100%' }}>
                                        <CardContent>
                                            <Typography variant='h6'>Interest</Typography>
                                            {
                                                Array.isArray(repayments) && (
                                                    <Typography variant='h4'><MoneyTextFormat value={Math.round(repayments.reduce((prev, cur) => prev + cur.interest, 0))} /></Typography>
                                                )
                                            }
                                            {
                                                !repayments && <Alert severity='warning'>
                                                    <Grid container item spacing={2} justifyItems="center">
                                                        <Grid item xs={12}>
                                                            It looks like you won't pay back your debt. Increase your monthly payment.
                                                        </Grid>

                                                    </Grid>
                                                </Alert>
                                            }
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>

                        </Grid>

                    </Paper>
                </Grid >
                <Grid container spacing={2} item xs={12}>
                    <Grid item xs={12}>
                        <Paper style={{ padding: '10px' }}>
                            <Line data={{
                                datasets: [{
                                    label: loan.name,
                                    data: repayments.map((p) => {
                                        return {
                                            x: p.year,
                                            y: p.endAmount
                                        }
                                    })
                                    ,
                                    borderColor: `#1976d2`
                                }],
                                labels
                            }} options={options}></Line>
                        </Paper>
                    </Grid>
                </Grid>
                <Period
                    monthly={true} periods={loan.periods} selectedPeriod={selectedPeriod} update={() => {
                        setLoan({
                            ...loan
                        })
                    }} editLink={(selYear) => {
                        var p = selYear && loan.periods.find((f) => selYear.format('YYYY-MM-DD') == f.start)
                        setSelectedPeriod(p ? p : loan.periods[loan.periods.length - 1])
                    }} getAmount={(period) => {
                        return period.monthly
                    }} addPeriod={(periods) => {
                        periods.push({
                            addition: 0,
                            apr: 0,
                            start: null,
                            monthly: 0,
                            end: null
                        })
                    }}>
                    <Grid container spacing={2} item xs={12}>
                        <Grid item xs={6}>
                            <InputLabel htmlFor="standard-adornment-amount">Starting Amount</InputLabel>
                            <TextField InputProps={{
                                inputComponent: MoneyFormat,
                                startAdornment: <InputAdornment position="start">£</InputAdornment>,

                            }} fullWidth placeholder={yearCalc?.startAmount ? Math.round(yearCalc?.startAmount) : 0} id="outlined-basic" key={`start-amount-${selectedPeriod?.start}`} defaultValue={selectedPeriod?.startAmount} onChange={(e) => {
                                setLoan({
                                    ...loan,
                                    periods: loan.periods.map((m) => {
                                        if (m.start == selectedPeriod?.start) {
                                            m.startAmount = parseFloat(e.target.value)
                                        }

                                        return m
                                    })
                                })
                            }} variant="outlined" />
                        </Grid>
                        <Grid item xs={6}>
                            <InputLabel htmlFor="standard-adornment-amount">Interest (APR)</InputLabel>
                            <TextField InputProps={{
                                inputComponent: PercentageFormat,
                            }} fullWidth id="outlined-basic" key={`apr-${selectedPeriod?.start}`} defaultValue={selectedPeriod?.apr * 100} onChange={(e) => {
                                setLoan({
                                    ...loan,
                                    periods: loan.periods.map((m) => {
                                        if (m.start == selectedPeriod?.start) {
                                            m.apr = parseFloat(e.target.value) / 100
                                        }

                                        return m
                                    })
                                })
                            }
                            } variant="outlined" />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} item xs={12}>
                        <Grid item xs={12}>
                            <InputLabel htmlFor="standard-adornment-amount">Monthly Addition</InputLabel>
                            <TextField InputProps={{
                                inputComponent: MoneyFormat,
                                startAdornment: <InputAdornment position="start">£</InputAdornment>,

                            }} fullWidth id="outlined-basic" key={`addition-${selectedPeriod?.start}`} defaultValue={selectedPeriod?.addition} onChange={(e) => {
                                setLoan({
                                    ...loan,
                                    periods: loan.periods.map((m) => {
                                        if (m.start == selectedPeriod.start) {
                                            m.addition = parseFloat(e.target.value) ? parseFloat(e.target.value) : 0
                                        }

                                        return m
                                    })
                                })
                            }
                            } variant="outlined" />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabel htmlFor="standard-adornment-amount">Max Monthly Payment</InputLabel>
                        <TextField InputProps={{
                            inputComponent: MoneyFormat,
                            startAdornment: <InputAdornment position="start">£</InputAdornment>,

                        }} fullWidth id="outlined-basic" key={selectedPeriod?.start} defaultValue={selectedPeriod?.monthly > 0 ? selectedPeriod?.monthly : null} onChange={(e) => {
                            setLoan({
                                ...loan,
                                periods: loan.periods.map((m) => {
                                    if (m.start == selectedPeriod?.start) {
                                        m.monthly = parseFloat(e.target.value)
                                    }

                                    return m
                                })
                            })
                        }
                        } variant="outlined" />
                    </Grid>

                </Period >

                <Grid container item spacing={2} justifyContent='flex-end'>
                    <Grid item>
                        <Button variant='outlined' onClick={(a) => {
                            setShowDelete({
                                delete: () => {
                                    financesDispatch({
                                        type: 'delete',
                                        financeType: 'credit',
                                        id: loan.id
                                    })
                                    setShowDelete(null)
                                    navigate(-1)
                                },
                                name: loan.name
                            })
                        }}>Delete</Button>
                    </Grid>
                    <Grid item>
                        {loan.id ? <Button onClick={() => { financesDispatch({ type: 'update', financeType: 'credit', item: loan }); navigate(-1) }} variant='contained'>Update Credit</Button> : <Button onClick={() => { financesDispatch({ type: 'add', financeType: 'credit', item: { ...loan, id: loan.name } }); navigate(-1) }} variant='contained'>Add Credit</Button>}
                    </Grid>
                </Grid>


                <Grid item xs={12}>
                    <Typography variant='h5'>Expenses</Typography>
                </Grid>
                <Grid container spacing={2} item xs={12}>
                    <Grid item xs={12}>
                        <Paper>
                            <DataGrid autoHeight disableColumnMenu disableRowSelectionOnClick columns={
                                [
                                    {
                                        field: 'id'
                                    },
                                    {
                                        field: 'expense',
                                        flex: 0.3,
                                        headerName: 'Expense'
                                    },
                                    {
                                        field: 'start',
                                        flex: 0.2,
                                        headerName: 'Start'
                                    },
                                    {
                                        field: 'amount',
                                        headerName: 'Amount',
                                        renderCell: (a) => {
                                            return <MoneyTextFormat value={a.row.amount} />
                                        }
                                    },
                                    {
                                        field: 'edit',
                                        align: 'right',
                                        flex: 0.1,
                                        renderCell: (a) => {
                                            return <Link to={`/expense/${a.id}`}><EditIcon /></Link>
                                        },
                                        headerName: ''
                                    },
                                    {
                                        field: 'delete',
                                        align: 'right',
                                        flex: 0.1,
                                        renderCell: (a) => {
                                            return <IconButton onClick={() => {
                                                setShowDelete({
                                                    delete: () => {
                                                        financesDispatch({
                                                            type: 'delete',
                                                            financeType: 'expense',
                                                            id: a.id
                                                        })
                                                        setShowDelete(null)
                                                    },
                                                    name: a.row.expense
                                                })
                                            }}><DeleteIcon /></IconButton>
                                        },
                                        headerName: ''
                                    }
                                ]
                            } initialState={{
                                columns: {
                                    columnVisibilityModel: {
                                        id: false,
                                        start: matches
                                    }
                                },
                                pagination: {
                                    paginationModel: {
                                        pageSize: 5
                                    }
                                }
                            }} rows={finances.expenses.flatMap((m) => {
                                return m.periods.filter((f) => f.pay != null && f.pay == loan.id).map((p) => {
                                    return {
                                        id: m.id,
                                        expense: m.type == 'other' ? m.customname : ExpenseMap[m.type],
                                        start: dayjs(p.start, 'YYYY-MM-DD').year(),
                                        amount: p.amount,
                                    }
                                })
                            })
                            } />
                        </Paper>
                    </Grid>
                </Grid>



            </Grid >
        </>


    );
}

export default CreditPayment;
