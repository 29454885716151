import { Button, Card, CardActions, CardContent, CardMedia, Grid, Typography } from "@mui/material"
import Carousel from 'react-material-ui-carousel'
import { useFinances } from "../store/FinancesContext"
import dayjs from "dayjs"
import { getIncomeAfterExpenses } from "../store/store"

var cards = [
    {
        type: 'loans',
        title: 'Worried about rising mortgage rates?',
        descripton: 'Rising interest rates are having an impact on mortgage and rent payments. Here’s what you need to do if you’re an existing homeowner and worried about falling behind or are trying to get onto the housing ladder for the first time.',
        image: 'https://www.moneyhelper.org.uk/content/dam/maps/en/money-troubles/dark-haired-young-woman-smiling.png.pic.1272.500.low.jpg',
        url: 'https://www.moneyhelper.org.uk/en/money-troubles/cost-of-living/worried-about-rising-mortgages'
    },
    {
        type: 'income',
        title: 'Benefits and work',
        descripton: 'If you’re self-employed or on a low income, it’s important to know that Universal Credit is replacing a number of benefits you would have normally claimed, including Tax Credits, Housing Benefit and Working Tax Credit.',
        image: 'https://www.moneyhelper.org.uk/content/dam/maps/en/l2-images/young-woman-using-mobile-phone.jpg.pic.624.312.low.jpg',
        url: 'https://www.moneyhelper.org.uk/en/benefits/benefits-and-work',
        filter: (finances, year) => {
            return finances.annualIncome[year].net < 30000 ? true : false
        }
    },
    {
        type: 'income',
        title: 'Benefits if you have children',
        descripton: 'Having a baby is both exciting and costly but there are a number of benefits and entitlements available to give a boost to your family budget.',
        image: 'https://www.moneyhelper.org.uk/content/dam/maps/en/l2-images/man-carrying-baby-on-his-back.jpg.pic.624.312.low.jpg',
        url: 'https://www.moneyhelper.org.uk/en/benefits/benefits-if-you-have-children'
    },
    {
        type: 'income',
        title: 'How to have a conversation about money',
        descripton: 'Throughout your life you’ll have lots of money conversations with different people. Some of these will be difficult, which can be stressful. Working out what you want to say will make it a lot easier.',
        image: 'https://www.moneyhelper.org.uk/content/dam/maps/en/l2-images/older-couple-sitting-talking-on-jetty-over-lake.jpg.pic.624.312.low.jpg',
        url: 'https://www.moneyhelper.org.uk/en/family-and-care/talk-money/how-to-have-a-conversation-about-money'
    },
    {
        type: 'savings',
        title: 'Reducing your pension contributions',
        descripton: 'If, like many of us at the moment, you are struggling with the cost of living, rising inflation and soaring energy prices, dipping into your pension may be a tempting solution for desperate times. Find out what you need to know before taking this step.',
        image: 'https://www.moneyhelper.org.uk/content/dam/maps/en/money-troubles/young-woman-smiling.png.pic.1272.500.low.jpg',
        url: 'https://www.moneyhelper.org.uk/en/money-troubles/cost-of-living/stopping-or-reducing-your-pension-contributions'
    },
    {
        type: 'savings',
        title: 'Building your retirement pot',
        descripton: 'The secret to a successful retirement is to slowly and surely build up your retirement pot. Exactly how you do that will depend on your situation – but there are lots of things you can do.',
        image: 'https://www.moneyhelper.org.uk/content/dam/maps/en/l2-images/man-and-young-boy-building-tower-using-blocks.jpg.pic.624.312.low.jpg',
        url: 'https://www.moneyhelper.org.uk/en/pensions-and-retirement/building-your-retirement-pot'
    },
    {
        type: 'savings',
        title: 'Regular savings accounts',
        descripton: 'With a regular savings account, you commit to paying in a certain amount each month. In return, the bank or building society gives you a higher interest rate than you’d get with their current account or ordinary savings account.',
        image: 'https://www.moneyhelper.org.uk/content/dam/maps/en/l2-images/man-and-young-boy-building-tower-using-blocks.jpg.pic.624.312.low.jpg',
        url: 'https://www.moneyhelper.org.uk/en/savings/types-of-savings/regular-savings-accounts'
    },
    {
        type: 'savings',
        title: 'Saving for your children',
        descripton: 'Saving for a child today is a great gift for their future. Not only can they start their adult lives with some savings, but getting kids involved early with saving also helps them learn important lessons about money.',
        image: 'https://www.moneyhelper.org.uk/content/dam/maps/en/l2-images/man-and-young-boy-building-tower-using-blocks.jpg.pic.624.312.low.jpg',
        url: 'https://www.moneyhelper.org.uk/en/savings/types-of-savings/saving-for-your-children'
    },
    {
        type: 'savings',
        title: 'Saving money for Christmas',
        descripton: 'After the fun of Christmas is over, many of us can be left facing a hefty bill in the New Year. It can be hard to avoid using credit to pay for the festive season but saving in the run-up will help soften the blow in January.',
        image: 'https://www.moneyhelper.org.uk/content/dam/maps/en/l2-images/man-and-young-boy-building-tower-using-blocks.jpg.pic.624.312.low.jpg',
        url: 'https://www.moneyhelper.org.uk/en/savings/types-of-savings/saving-money-for-christmas'
    },
    {
        type: 'savings',
        title: 'Investing',
        descripton: 'Investing your money can be a great next step when you’ve got to grips with saving. You can potentially make your savings grow faster than simply putting them in a savings account. But it also comes with risks, as you could end up with less money than you started with.',
        image: 'https://www.moneyhelper.org.uk/content/dam/maps/en/l2-images/child-pulling-hat-down-over-womans-eyes.jpg.pic.624.312.low.jpg',
        url: 'https://www.moneyhelper.org.uk/en/savings/investing'
    },
    {
        type: 'income',
        title: 'Where can I get emergency help with money and food?',
        descripton: 'If you run out of money or face an unexpected cost that you need to pay for now, this guide helps you find organisations that offer a range of urgent practical support depending on your circumstance.',
        image: 'https://www.moneyhelper.org.uk/content/dam/maps/en/money-troubles/smiling-woman-short-hair-puffy-sleeved-top.png.pic.1272.500.low.jpg',
        url: 'https://www.moneyhelper.org.uk/en/money-troubles/cost-of-living/where-can-i-get-emergency-help-with-money-and-food',
        filter: (finances, year) => {
            return getIncomeAfterExpenses(finances,year) < 1200 ? true : false
        }
    },
    {
        type: 'expenses',
        title: 'Living on a squeezed income',
        descripton: 'Whatever is going on in your life, there are ways to make your income go further. Learn how to cut back on costs and see what extra help is available.',
        image: 'https://www.moneyhelper.org.uk/content/dam/maps/en/money-troubles/young-father-holding-toddler-son.png.pic.1272.500.low.jpg',
        url: 'https://www.moneyhelper.org.uk/en/money-troubles/cost-of-living/squeezed-income',
        filter: (finances, year) => {
            return getIncomeAfterExpenses(finances,year) < 1200 ? true : false
        }
    },
    {
        type: 'expenses',
        title: 'How to reduce your energy bills',
        descripton: 'Are you worried about paying your energy bills? Find out what help\'s available to you if you’re concerned about paying bills or missing a payment.',
        image: 'https://www.moneyhelper.org.uk/content/dam/maps/en/money-troubles/young-father-holding-toddler-son.png.pic.1272.500.low.jpg',
        url: 'https://www.moneyhelper.org.uk/en/everyday-money/budgeting/save-money-on-your-gas-and-bills'
    },
    {
        type: 'expenses',
        title: 'How to reduce your water bill',
        descripton: 'Unlike other household bills, you can’t switch to a different water provider – but you can still save. Here’s how to check if a water meter could get you cheaper bills, tips to reduce your water use and if you qualify for a low-income tariff.',
        image: 'https://www.moneyhelper.org.uk/content/dam/maps/en/l2-images/child-pulling-hat-down-over-womans-eyes.jpg.pic.624.312.low.jpg',
        url: 'https://www.moneyhelper.org.uk/en/everyday-money/budgeting/save-money-on-your-water-bill'
    },
    {
        type: 'expenses',
        title: 'How to save money on household bills',
        descripton: 'Household bills continue to rise across the UK, but there are still some easy ways to cut costs. The average household can save hundreds of pounds each year by following our tips.',
        image: 'https://www.moneyhelper.org.uk/content/dam/maps/en/l2-images/child-pulling-hat-down-over-womans-eyes.jpg.pic.624.312.low.jpg',
        url: 'https://www.moneyhelper.org.uk/en/everyday-money/budgeting/how-to-save-money-on-household-bills'
    },
    {
        type: 'expenses',
        title: 'What is the average cost of utility bills per month?',
        descripton: 'HoIf you’re one of the many households wondering how you’re going to manage with the increasing cost of gas and electricity, you are not alone. While individual bills will come down to your own usage, it’s useful to have an idea of the average likely costs.',
        image: 'https://www.moneyhelper.org.uk/content/dam/maps/en/blog/banners/hand-using-smart-energy-hub.png.pic.1272.500.low.jpg',
        url: 'https://www.moneyhelper.org.uk/en/blog/utilities/what-is-the-average-cost-of-utility-bills-per-month'
    },
    {
        type: 'expenses',
        title: 'Get help to pay your rent',
        descripton: 'If your landlord is increasing your rent or you’re worried about going into rent arrears or your landlord is selling up and you need to find somewhere new, this guide helps you find support to cover the costs.',
        image: 'https://www.moneyhelper.org.uk/content/dam/maps/en/money-troubles/older-man-smiling.png.pic.1272.500.low.jpg',
        url: 'https://www.moneyhelper.org.uk/en/money-troubles/cost-of-living/get-help-to-pay-your-rent',
        filter: (finances, year) => {
            if (year) {
                 if (finances.expenses.filter((f) => f.type == 'rent').find((e) => e.periods.find((f) => (f) => year >= dayjs(f.start).year() && (!f.end || year <= dayjs(f.end).year())))) {
                    return true
                 }
            } else {
                if (finances.expenses.find((f) => f.type == 'rent')) {
                    return true
                }

                return false
            }
        }
    },
    {
        type: 'loans',
        title: 'Dealing with debt',
        descripton: 'You’ve taken the first important step by reading about dealing with debt, so you’ll be pleased to know you’re in the right place as our guidance is easy to understand and practical.',
        image: 'https://www.moneyhelper.org.uk/content/dam/maps/en/home-page/older-woman-smiling.jpg.pic.624.312.low.jpg',
        url: 'https://www.moneyhelper.org.uk/en/money-troubles/dealing-with-debt',
        filter: (finances, year) => {
            if (year) {
                if (finances.loans.filter((l) => l.periods.find((f) => year >= dayjs(f.start).year() && (!f.end || year <= dayjs(f.end).year()))) > 0) {
                    return true
                }

                return false
            }

            return true
        }
    },
    {
        type: 'loans',
        title: 'Help to Buy scheme',
        descripton: 'Help to Buy is a government scheme to help first-time buyers get a property with just a 5% deposit. You can borrow 20% of the purchase price (in London it is 40%), interest-free for five years. ',
        image: 'https://www.moneyhelper.org.uk/content/dam/maps/en/l2-images/man-lying-on-bed-using-laptop-while-young-boy-plays-on-his-back.jpg.pic.624.312.low.jpg',
        url: 'https://moneyhelper.org.uk/en/homes/buying-a-home/help-to-buy-scheme-everything-you-need-to-know',
        filter: (finances) => {
            return finances.loans?.find((f) => f.type == 'mortgage') ? false : true
        }
    },
    {
        type: 'loans',
        title: 'Mortgage arrears or problems paying your mortgage',
        descripton: 'If you’re struggling with your mortgage repayments and can’t get back on track it’s important you don’t ignore the problem. There’s a lot of help available.',
        image: 'https://www.moneyhelper.org.uk/content/dam/maps/en/l2-images/man-lying-on-bed-using-laptop-while-young-boy-plays-on-his-back.jpg.pic.624.312.low.jpg',
        url: 'https://www.moneyhelper.org.uk/en/homes/buying-a-home/mortgage-arrears-if-you-have-problems-paying-your-mortgage',
        filter: (finances) => {
            return finances.loans?.find((f) => f.type == 'mortgage') ? true : false
        }
    },
    {
        type: 'credit',
        title: 'Using credit wisely',
        descripton: 'Credit is a useful tool to help you spread costs, especially for bulk buying of everyday items if you can save money. But if you find you’re not meeting loan payments or are using credit to pay for essential items like food, you need to take action.',
        image: 'https://www.moneyhelper.org.uk/content/dam/maps/en/money-troubles/young-man-smiling.png.pic.1272.500.low.jpg',
        url: 'https://www.moneyhelper.org.uk/en/money-troubles/cost-of-living/using-credit-wisely'
    }
]


function AdviceCards(props) {
    var finances = useFinances()
    // TODO multiline header height... on mobile not best
    return <Grid container item component={Carousel} autoPlay={false}>
        {
            cards.filter((f) => f.type == props.type).filter((f) => f.filter ? f.filter(finances,props.year) : true).sort(() => Math.random() - 0.5).map((m) => {
                return <Grid style={{ display: "flex",
                flexDirection: "column", height: '370px' }} component={Card} xs={12}>
                    <CardMedia
                        component="img"
                        alt="green iguana"
                        height="140"
                        image={m.image}
                    />
                    <CardContent style={{ minHeight: '150px' }}>
                        <Typography gutterBottom variant="h5" component="div">
                            {m.title}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            {m.descripton}
                        </Typography>
                    </CardContent>
                    <CardActions style={{ mt: "auto" , justifyContent: 'end'  }}>
                        <Button target='_blank' href={m.url} size="small">Learn More</Button>
                    </CardActions>
                </Grid>
            })
        }
    </Grid>


}

export default AdviceCards